// Vendor
import { Module } from 'vuex';

// Types
import { UserInterfaceState } from '@/@types/store/userInterface';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): UserInterfaceState => ({});

const userInterface: Module<UserInterfaceState, RootState> = {
	namespaced: true,
	state: defaultState(),
	actions,
	getters,
	mutations,
};

export { userInterface };
