// Vendor
import { GetterTree } from 'vuex';

// Types
import { ProductState } from '@/@types/store/product';
import { RootState } from '@/@types/store';

const getters: GetterTree<ProductState, RootState> = {
    data: (state) => state.current,
    loaded: (state) => state.loaded
};

export { getters };
