// Vendor
import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { Preferences } from '@capacitor/preferences';
import { DynamicURL, useGTM } from '@netvlies/utility-collection';
import queryString from 'query-string';
import * as Sentry from '@sentry/vue';

// Types
import {
	AuthCredentials,
	AuthenticationResponse,
	AuthResponse,
	AuthState,
	FetchRefreshTokenOptions,
	FetchTokenOptions,
	GrantType,
	LoginPayload,
	RegistrationResponse,
	RequestPasswordOptions,
	SetTokenOptions,
} from '@/@types/store/auth';
import { CoreAction } from '@/@types/store/app';
import { StudentRegistrationData } from '@/@types/store/student';
import { RegistrationOptions } from '@/@types/store/auth';
import { RootState } from '@/@types/store';

// Constants
import { ACCESS_TOKEN_KEY, APP_ACCESS_KEY, REFRESH_TOKEN_KEY, ROLE } from '@/@constants/auth';
import { RESET_ACTIONS } from '@/@constants/app';

// API
import { API, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';

// Plugins
import { i18n } from '@/translations';
import { store } from '@/store';
import { FlexpulseResponse } from '@/@types/api';

const { VUE_APP_API_CLIENT_ID } = process.env || {};
const { t } = i18n.global;
const { push: pushGTM } = useGTM();

export const actions: ActionTree<AuthState, RootState> = {
	initAuth: async ({ dispatch }, updateState: boolean): Promise<boolean> => {
		const accesToken = await Preferences.get({
			key: ACCESS_TOKEN_KEY,
		});

		const refreshToken = await Preferences.get({
			key: REFRESH_TOKEN_KEY,
		});

		const appToken = await Preferences.get({
			key: APP_ACCESS_KEY,
		});

		/**
		 * Token override can be used by Cypress tests
		 */
		const tokenOverride: Record<string, string> = JSON.parse(
			window.localStorage.getItem('tokenOverride') as string
		);

		if (updateState) {
			const tokenPayload: SetTokenOptions = {
				result: {
					[ACCESS_TOKEN_KEY]: tokenOverride
						? tokenOverride[ACCESS_TOKEN_KEY]
						: accesToken.value || '',
					[REFRESH_TOKEN_KEY]: tokenOverride
						? tokenOverride[REFRESH_TOKEN_KEY]
						: refreshToken.value || '',
					token_type: 'Bearer',
				},
				updateState: true,
			};

			dispatch('setTokens', tokenPayload);
			dispatch('setTokens', {
				result: {
					[ACCESS_TOKEN_KEY]: appToken.value || '',
				},
				updateState: true,
				mode: 'app',
			});
		}

		return tokenOverride !== null || accesToken.value !== null;
	},
	fetchTokens: async (
		{ dispatch },
		options: FetchTokenOptions | FetchRefreshTokenOptions
	): Promise<AuthResponse | boolean> => {
		const { mode = 'student' } = options;

		const url = new DynamicURL(ENDPOINTS.TOKEN).setQueryParams({
			_format: 'json',
		});

		const request: AxiosResponse = await API.post(url.resolve(), options, {
			apiKey: true,
			token: 'secure',
		}).catch((error) => {
			if (options.grant_type !== 'refresh_token') {
				store.dispatch('userInterface/showToast', {
					color: 'danger',
					text: t('auth.error.general'),
				});
			}

			return Promise.reject(error);
		});

		if (request?.data?.result) {
			const { result } = request.data;

			if (options.updateState) {
				await dispatch('setTokens', {
					result,
					updateState: true,
					updateStorage: true,
					mode,
				});
			}

			return result;
		}

		return false;
	},
	login: async (
		{ dispatch, rootGetters },
		payload: LoginPayload
	): Promise<AuthenticationResponse | boolean> => {
		const { fetchStudent = true } = payload;

		const apiUrl = setQueryParams(ENDPOINTS.AUTHORIZE, 'json', {
			client_id: VUE_APP_API_CLIENT_ID,
			redirect: 0,
			response_type: 'code',
			state: 'xyz',
		});

		const bodyData = Object.keys(payload.formData)
			.map((name) => `${name}=${encodeURIComponent(payload.formData[name])}`)
			.join('&');

		const request: AxiosResponse<FlexpulseResponse<AuthenticationResponse>> = await API.post(
			apiUrl,
			bodyData,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
				},
			}
		).catch((error) => {
			store.dispatch('userInterface/showToast', {
				color: 'danger',
				text: t('auth.error.general'),
			});

			return Promise.reject(error);
		});

		if (request?.data?.error) {
			store.dispatch('userInterface/showToast', {
				color: 'danger',
				text: t('auth.wrong_credentials'),
			});

			return Promise.reject(request.data.error);
		}

		if (request.data?.result?.location) {
			const url = new URL(request.data?.result?.location);
			const { code } = queryString.parse(url.search);

			if (typeof code === 'string') {
				const fetchTokenOptions: FetchTokenOptions = {
					code,
					grant_type: 'authorization_code',
					updateState: true,
				};
				await dispatch('fetchTokens', fetchTokenOptions);

				dispatch('setRole', ROLE.STUDENT);

				if (fetchStudent) {
					await store.dispatch('app/fetchAppCoreData', true);
				}
			}
		}

		pushGTM('login', {
			user_id: rootGetters['student/data'].id,
		});

		Sentry.setUser({
			id: rootGetters['student/data'].id,
			email: rootGetters['student/data'].email,
		});

		Sentry.captureMessage('User logged in', 'info');

		return request?.data?.result || false;
	},
	logout: async ({ dispatch }, expired = false): Promise<void> => {
		await Preferences.remove({
			key: ACCESS_TOKEN_KEY,
		});

		await Preferences.remove({
			key: REFRESH_TOKEN_KEY,
		});

		await dispatch('setRole', ROLE.ANONYMOUS);

		RESET_ACTIONS.map((action: CoreAction) => {
			store.dispatch(action.name, action.options);
		});

		// Reset route progress
		await store.dispatch('progress/fetchProgressCard', {
			query: {
				getProgCardOrLesson: 0,
				getLatestLesson: 1,
			},
			setHomeworkNotifications: true,
			updateState: true,
		});

		await store.dispatch('router/pushRoute', {
			direction: 'root',
			name: 'tabs.route',
		});

		Sentry.captureMessage('User logged out', 'info');

		if (expired) {
			return store.dispatch('userInterface/showToast', {
				color: 'danger',
				text: t('auth.logout.expired'),
			});
		}

		return store.dispatch('userInterface/showToast', {
			color: 'success',
			text: t('auth.logout.success'),
		});
	},
	postRegistration: async (
		{ dispatch, getters },
		options: { registrationData: StudentRegistrationData; method: string }
	): Promise<RegistrationResponse | boolean> => {
		const { registrationData, method = 'Wolf App' } = options || getters;

		const bodyData = {
			lastName: '',
			referal: method,
			category: 3,
			...registrationData,
			cf1: registrationData.city,
			agreed: 1,
		};

		const request: AxiosResponse<FlexpulseResponse<RegistrationResponse>> = await API.post(
			setQueryParams(ENDPOINTS.STUDENT, 'json'),
			bodyData,
			{
				apiKey: true,
				token: 'secure_app',
			}
		).catch((error) => {
			store.dispatch('userInterface/showToast', {
				color: 'danger',
				text: t(`error.code.${error.data.error.errorCode}`),
			});

			return Promise.reject(error);
		});

		if (request.data.result) {
			const { result } = request.data;

			pushGTM('sign_up', {
				method,
				data: bodyData,
			});

			Sentry.captureMessage('User registered', 'info');

			const authTokenSettings: FetchTokenOptions = {
				code: result.authorization_code,
				grant_type: 'authorization_code',
				updateState: true,
			};

			await dispatch('fetchTokens', authTokenSettings);

			await store.dispatch('app/fetchAppCoreData', true);

			await dispatch('setRole', ROLE.STUDENT);
		}

		return request?.data?.result || false;
	},
	requestPassword: async (_, credentials: AuthCredentials): Promise<boolean> => {
		if (!credentials?.email) throw new Error('no email provided');

		const bodyData = `email=${encodeURIComponent(credentials.email || '')}`;

		const request: AxiosResponse = await API.post(ENDPOINTS.PASSWORD, bodyData, {
			token: 'secure_app',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
			},
		}).catch((error: AxiosResponse) => {
			return Promise.reject(error);
		});

		return request?.data;
	},
	saveRegistrationStep: async ({ dispatch }, options: RegistrationOptions): Promise<void> => {
		const { student } = options || {};

		await dispatch('setRegistrationData', student);
	},
	setRegistrationData: ({ commit }, student: StudentRegistrationData): void => {
		commit('SET_REGISTRATION_DATA', student);
	},
	setTokens: async ({ commit }, options: SetTokenOptions): Promise<void> => {
		const { result } = options;

		Sentry.setTags({
			access_token: result.access_token,
			refresh_token: result.refresh_token,
		});

		if (options.updateStorage === true && options.mode === 'student') {
			await Preferences.set({
				key: ACCESS_TOKEN_KEY,
				value: result.access_token,
			});

			await Preferences.set({
				key: REFRESH_TOKEN_KEY,
				value: result.refresh_token,
			});
		} else if (options.updateStorage === true && options.mode === 'app') {
			await Preferences.set({
				key: APP_ACCESS_KEY,
				value: result.access_token,
			});
		}

		if (options.updateState) {
			if (options.mode === 'app') {
				commit('SET_APP_TOKENS', result);
			} else {
				commit('SET_ACCESS_TOKEN', result.access_token);
				commit('SET_REFRESH_TOKEN', result.refresh_token);
			}
		}
		return;
	},
	setRole: ({ commit }, role: ROLE): void => {
		commit('SET_ROLE', role);
	},
};
