// Vendor
import { RouteRecordRaw } from 'vue-router';

// Plugins
import { t } from '@/translations';

const wolfRoutes: RouteRecordRaw[] = [
	{
		meta: {
			title: 'routes.route.title',
		},
		name: 'tabs.route',
		path: t('routes.route.slug'),
		component: () => import('@/views/WolfRoute/Route.vue'),
	},
	{
		meta: {
			backButtonText: 'routes.route.title',
			title: 'routes.route.title',
		},
		name: 'tabs.route.content_page',
		path: `${t('routes.route.slug')}/p/:slug`,
		component: () => import('@/views/Content/Single.vue'),
	},
	{
		meta: {
			backButtonText: 'routes.route.title',
			title: 'routes.route.title',
		},
		name: 'tabs.route.videos',
		path: `${t('routes.route.slug')}/${t('routes.videos.slug')}`,
		component: () => import('@/views/Videos/Overview.vue'),
	},
	{
		meta: {
			backButtonText: 'routes.route.title',
			title: 'routes.route.title',
		},
		name: 'tabs.route.videos.single',
		path: `${t('routes.route.slug')}/${t('routes.videos.slug')}/:chapter/:video`,
		component: () => import('@/views/Videos/Single.vue'),
	},
	{
		meta: {
			backButtonText: 'routes.route.title',
			title: 'routes.student_detail.title',
		},
		name: 'tabs.route.student_detail',
		path: `${t('routes.route.slug')}/${t('routes.student_detail.slug')}`,
		component: () => import('@/views/Student/Detail.vue'),
	},
];

export { wolfRoutes };
