// Vendor
import { MutationTree } from 'vuex';

// Types
import { Instructor, InstructorState } from '@/@types/store/instructor';

import { defaultState } from './index';

const mutations: MutationTree<InstructorState> = {
    RESET_INSTRUCTOR (state: InstructorState): void {
        Object.assign(state, {
            ...defaultState()
        })
    },
    SET_INSTRUCTOR_DATA(state: InstructorState, payload: Instructor): void {
        state.data = payload;
    },
    SET_INSTRUCTOR_LOADED(state: InstructorState, payload: boolean): void {
        state.loaded = payload;
    },
};

export { mutations };
