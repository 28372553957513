// Vendor
import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { App } from '@capacitor/app';
import { Share } from '@capacitor/share';
import * as Sentry from '@sentry/vue';

// Types
import {
	AppState,
	CoreAction,
	FetchAppVersionOptions,
	RemoteAppData,
	SendMailOptions,
} from '@/@types/store/app';
import { RootState } from '@/@types/store';

// Plugins
import { API, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { i18n } from '@/translations';

const { t } = i18n.global;

const actions: ActionTree<AppState, RootState> = {
	fetchAppCoreData: async ({ dispatch, getters }, isLoggedIn: boolean): Promise<boolean> => {
		const { coreActions = [] }: { coreActions: CoreAction[] } = getters;

		for (const action of coreActions) {
			if (action.auth === undefined || action.auth === isLoggedIn) {
				await dispatch(
					action.name,
					{
						...action.options,
						updateState: true,
					},
					{ root: true }
				).catch((error) => Promise.reject(error));
			}
		}

		await dispatch('setAppLoaded', true);

		return true;
	},
	fetchLocalAppVersion: async (
		{ dispatch },
		options: FetchAppVersionOptions
	): Promise<string> => {
		const { updateState = true } = options || {};

		const app = await App.getInfo().catch((reason) => {
			return reason.code === 'UNIMPLEMENTED'
				? Promise.resolve({
						version: 'web',
				  })
				: Promise.reject(reason);
		});

		if (updateState) {
			dispatch('setLocalAppVersion', app.version);
			// Set sentry release
			Sentry.setTags({
				version: app.version,
			});
		}

		return app.version;
	},
	fetchRemoteAppVersion: async (
		{ dispatch, getters },
		updateState = true
	): Promise<RemoteAppData | null> => {
		const { isConnected }: { isConnected: boolean } = getters;

		const url = setQueryParams(ENDPOINTS.REMOTE_APP_VERSION, 'usable_json');

		const remoteAppResponse: AxiosResponse<RemoteAppData> | null = await API.get(url, {
			apiKey: true,
			token: 'secure',
		}).catch(async () => {
			if (isConnected) {
				dispatch(
					'userInterface/showToast',
					{
						color: 'danger',
						text: t('app.remote_version.fetch.error'),
					},
					{ root: true }
				);
			}

			return Promise.resolve(null);
		});

		const { data } = remoteAppResponse || {};

		if (data) {
			if (updateState) {
				dispatch('setRemoteApp', data);
				Sentry.setTags({
					remote_version: data.app_version,
				});
			}

			return data;
		}

		return null;
	},
	sendMail: async ({ dispatch }, options: SendMailOptions): Promise<void> => {
		const url = setQueryParams(ENDPOINTS.MAIL, 'usable_json');

		const request: AxiosResponse = await API.post(url, options, {
			apiKey: true,
		}).catch((error: AxiosResponse) => {
			dispatch(
				'userInterface/showToast',
				{
					color: 'danger',
					text: t('form.mail.error'),
				},
				{ root: true }
			);

			return Promise.reject(error);
		});

		return request?.data;
	},
	setCanShare: async ({ commit }): Promise<void> => {
		const shareCheck = await Share.canShare();
		return commit('SET_CAN_SHARE', shareCheck.value);
	},
	setAppLoaded: ({ commit }, payload: boolean): void => {
		return commit('SET_APP_LOADED', payload);
	},
	setLocalAppVersion: ({ commit }, version: string): void => {
		commit('SET_LOCAL_APP_VERSION', version);
	},
	setRemoteApp: ({ commit }, app: RemoteAppData): void => {
		commit('SET_REMOTE_APP', app);
	},
	setLocked: ({ commit }, locked: boolean): void => {
		commit('SET_LOCKED', locked);
	},
};

export { actions };
