// Types
import { Instructor } from '@/@types/store/instructor';

// Constants
import { DEFAULT_PHONE_NR } from '@/@constants/contact';

// Helpers
import { converToPhoneNumber } from '@/helpers/phone';

export const createChat = (instructor?: Instructor, general = false): Window | null | void => {
	const phoneNr =
		instructor?.plannerPhone && general === false
			? converToPhoneNumber(instructor.plannerPhone)
			: DEFAULT_PHONE_NR;

	return window.open(`https://api.whatsapp.com/send?phone=${phoneNr}`, '_blank');
};
