// Vendor
import { ActionTree } from 'vuex';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { LocalNotificationSchema } from '@capacitor/local-notifications';

// Types
import { FetchProgressOptions, ProgressCardEntries, ProgressState } from '@/@types/store/progress';
import { RootState } from '@/@types/store';

// Plugins
import { API, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { store } from '@/store';
import { i18n } from '@/translations';
import dayjs from 'dayjs';
import { stripChapterNumbers } from '@/helpers/text';

const { t, locale } = i18n.global;

const actions: ActionTree<ProgressState, RootState> = {
	fetchProgressCard: async (
		{ dispatch, rootGetters },
		options: FetchProgressOptions
	): Promise<AxiosResponse | boolean> => {
		if (options.updateState && options.ignoreLoadingState !== true) {
			dispatch('setProgressCardLoaded', false);
		}

		const url = setQueryParams(ENDPOINTS.PROGRESS_CARD, 'json', options.query);

		const requestOptions: AxiosRequestConfig = {
			apiKey: true,
			token: rootGetters['auth/isLoggedIn'] ? 'secure' : 'secure_app',
		};

		const request: AxiosResponse = await API.get(url, requestOptions).catch(
			(error: AxiosResponse) => {
				store.dispatch('userInterface/showToast', {
					color: 'danger',
					text: t('progress.card.error.fetch'),
				});

				return Promise.reject(error);
			}
		);

		if (request?.data && options.updateState) {
			const { result } = request.data;

			await dispatch('setProgressCardEntries', result);
			await dispatch('setProgressCardLoaded', true);

			if (options.setHomeworkNotifications) {
				const cardsWithHomeWork: ProgressCardEntries =
					rootGetters['progress/cardEntriesWithHomeWork'];
				const targetDate = dayjs().hour(19).minute(0).second(0);

				if (cardsWithHomeWork.length > 0 && dayjs().isBefore(targetDate)) {
					const notification: LocalNotificationSchema = {
						actionTypeId: 'router_setLocation',
						body: t('student.notification.homework.body', {
							section: stripChapterNumbers(
								rootGetters['progress/cardCategoryTitleByNumber'](
									Number(cardsWithHomeWork[0].description?.charAt(0))
								).toLowerCase()
							),
						}),
						extra: {
							options: `/${locale}/t/${t('routes.tasks.slug')}`,
						},
						id: Number(cardsWithHomeWork[0].description?.charAt(0)),
						schedule: {
							at: new Date(`${targetDate}`),
						},
						title: t('student.notification.homework.title'),
					};

					await store.dispatch('notification/scheduleLocalNotifications', [notification]);
				}
			}
		}

		return request?.data || false;
	},
	setProgressCardEntries: ({ commit }, payload: ProgressCardEntries): void => {
		return commit('SET_PROGRESS_CARD_ENTRIES', payload);
	},
	setProgressCardLoaded: ({ commit }, payload: boolean): void => {
		return commit('SET_PROGRESS_CARD_LOADED', payload);
	},
};

export { actions };
