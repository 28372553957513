// Vendor
import {
	calendarOutline,
	readerOutline,
	bookOutline,
	carOutline,
	schoolOutline,
} from 'ionicons/icons';

// Types
import { Task } from '@/@types/store/task';

// Plugins
import { t } from '@/translations';

export const STATIC_TASKS: Task[] = [
	{
		title: 'tasks.tasks.plan_lesson.title',
		icon: calendarOutline,
		action: {
			name: 'router/pushRoute',
			options: {
				name: 'tabs.appointment',
				direction: 'root',
				chain: {
					name: 'tabs.appointment.planner',
				},
			},
		},
	},
	{
		title: 'tasks.tasks.medic.title',
		icon: readerOutline,
		action: {
			name: 'router/pushRoute',
			options: {
				name: 'tabs.tasks.content_page',
				params: {
					slug: t('routes.medical_declaration.slug'),
				},
			},
		},
	},
];

export const THEORY_TASK: Task = {
	title: 'tasks.tasks.theory_lessons.title',
	icon: bookOutline,
	action: {
		name: 'router/pushRoute',
		options: {
			name: 'tabs.tasks.content_page',
			params: {
				slug: t('routes.theory_package.slug'),
			},
		},
	},
};

export const THEORY_DATE_TASK: Task = {
	title: 'tasks.tasks.theory_date.title',
	icon: schoolOutline,
	action: {
		name: 'router/pushRoute',
		options: {
			name: 'tabs.more.student_detail',
			params: {
				slug: t('routes.student_detail.slug'),
			},
		},
	},
};

export const AUTHORIZE_TASK: Task = {
	title: 'tasks.tasks.authorize.title',
	icon: readerOutline,
	action: {
		name: 'router/pushRoute',
		options: {
			name: 'tabs.tasks.content_page',
			params: {
				slug: t('routes.authorization_final_exam.slug'),
			},
		},
	},
};

export const TRIAL_LESSON_TASK: Task = {
	title: 'tasks.tasks.plan_trial.title',
	icon: carOutline,
	action: {
		name: 'router/pushRoute',
		options: {
			name: 'trial.1',
			direction: 'root',
		},
	},
};
