// Vendor
import { MutationTree } from 'vuex';

// Types
import { OrderState, Order } from '@/@types/store/order';

const mutations: MutationTree<OrderState> = {
    SET_CURRENT_ORDER(state: OrderState, payload: Order[]): void {
        state.current = payload;
    },
    SET_PROCESSING(state: OrderState, payload: boolean): void {
        state.processing = payload;
    },
};

export { mutations };
