// Vendor
import { createI18n } from 'vue-i18n';

// Constants
import { DEFAULT_LOCALE } from '@/@constants/locale';

// Messages
import { datetimeFormats as nlDatetimeFormats } from './nl/datetime';
import nlNumber from './nl/number';
import nlMessage from './nl/message';

const i18n = createI18n({
	legacy: false,
	locale: DEFAULT_LOCALE,
	fallbackLocale: DEFAULT_LOCALE,
	silentTranslationWarn: false,
	numberFormats: {
		'nl-NL': nlNumber,
	},
	datetimeFormats: {
		'nl-NL': nlDatetimeFormats,
	},
	messages: {
		nl_NL: nlMessage,
	},
});

const { t, locale } = i18n.global;

export { i18n, t, locale };
