// Vendor
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { ActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Keyboard } from '@capacitor/keyboard';
import { App as CapApp } from '@capacitor/app';
import { ConnectionStatus, Network } from '@capacitor/network';
import { IonicVue } from '@ionic/vue';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import toArray from 'dayjs/plugin/toArray';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/nl';
import * as Sentry from '@sentry/vue';

const { VUE_APP_SENTRY_ENV } = process.env || {};

// Plugins
import { i18n } from '@/translations';
import { router } from '@/router';
import { store } from '@/store';

import { componentBundle } from '@/plugins/component/plugin';
import { coreBundle } from '@/plugins/core/plugin';

// Constants
import { ROLE } from '@/@constants/auth';

// App wrapper
import App from '@/App.vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.css';
import '@/scss/main.scss';

dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(toArray);

const app = createApp(App)
	.use(createPinia())
	.use(IonicVue)
	.use(componentBundle)
	.use(coreBundle)
	.use(i18n)
	.use(store);

Sentry.init({
	app,
	dsn: 'https://21a21c34da02478a8b57b6705f485d67@sentry.netvlies.nl/167',
	environment: VUE_APP_SENTRY_ENV,
	integrations: [Sentry.browserTracingIntegration({ router })],
	tracesSampleRate: 1.0,
});

store.dispatch('auth/initAuth', true).then(async (isLoggedIn) => {
	await store.dispatch('auth/setRole', isLoggedIn ? ROLE.STUDENT : ROLE.ANONYMOUS);

	/**
	 * Locale settings
	 */
	const locale = i18n.global.locale.value.split('_')[0];
	window.document.documentElement.setAttribute('lang', locale);
	dayjs.locale(locale);

	app.use(router);

	router.isReady().then(async () => {
		Keyboard.setAccessoryBarVisible({
			isVisible: true,
		}).catch((reason) =>
			// Prevent web version from throwing errors
			reason.code === 'UNIMPLEMENTED' ? Promise.resolve(reason) : Promise.reject(reason)
		);

		store.dispatch('network/fetchNetworkStatus', {
			updateState: true,
		});

		Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
			store.dispatch('network/setNetworkStatus', status);
		});

		store.dispatch('app/fetchRemoteAppVersion');
		CapApp.addListener('appStateChange', (state) => {
			if (state.isActive) {
				store.dispatch('app/fetchRemoteAppVersion');
			}
		});

		SplashScreen.hide();

		StatusBar.setStyle({
			style: Style.Dark,
		}).catch((reason) =>
			// Prevent web version from throwing errors
			reason.code === 'UNIMPLEMENTED' ? Promise.resolve(reason) : Promise.reject(reason)
		);

		/**
		 * Android doesn't allow / in actionTypeId, so we snake case it
		 * and transform it back in the execution of the action
		 */
		LocalNotifications.addListener(
			'localNotificationActionPerformed',
			(action: ActionPerformed) => {
				setTimeout(() => {
					if (action.notification.actionTypeId) {
						store.dispatch(
							action.notification.actionTypeId.replace('_', '/'),
							action.notification.extra?.options
						);
					}
				}, 200);
			}
		);

		store.dispatch('app/fetchAppCoreData', isLoggedIn).finally(() => {
			app.mount('#app');

			store.dispatch('notification/requestPermission', {
				updateState: true,
			});
		});
	});
});
