// Vendor
import { Module } from 'vuex';

// Types
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';

const router: Module<any, RootState> = {
    namespaced: true,
    actions,
};

export { router };
