// Vendor
import { App } from 'vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

// Plugin
import { registerComponents } from './components';
import { setupCoreFormRules } from './form';

// Bundles
import { UserInterfaceBundle } from './userInterface.bundle';

export const install = (app: App): void => {
	registerComponents(app);
	setupCoreFormRules();
	dayjs.extend(duration);

	const initYoutubeIframeApi = () => {
		window.YTConfig = {
			host: window.location.origin,
		};

		const tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		tag.async = true;
		document.head.insertBefore(tag, document.head.lastElementChild);
	};

	initYoutubeIframeApi();

	app.config.globalProperties.$ui = new UserInterfaceBundle();
};
