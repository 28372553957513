// Vendor
import { GetterTree } from 'vuex';

// Types
import { NotificationState } from '@/@types/store/notification';
import { RootState } from '@/@types/store';

const getters: GetterTree<NotificationState, RootState> = {
    hasPermission: (state): PermissionState => state.permission
};

export { getters };
