// Vendor
import { MutationTree } from 'vuex';

// Types
import { AuthState } from '@/@types/store/auth';

// Constants
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, ROLE } from '@/@constants/auth';
import { StudentRegistrationData } from '@/@types/store/student';

const mutations: MutationTree<AuthState> = {
	SET_ACCESS_TOKEN(state, payload: string): void {
		state[ACCESS_TOKEN_KEY] = payload;
	},
	SET_APP_TOKENS(
		state,
		payload: { [ACCESS_TOKEN_KEY]: string; [REFRESH_TOKEN_KEY]: string | undefined }
	): void {
		state.appTokens[ACCESS_TOKEN_KEY] = payload[ACCESS_TOKEN_KEY];
		state.appTokens[REFRESH_TOKEN_KEY] = payload[REFRESH_TOKEN_KEY];
	},
	SET_ROLE(state, role: ROLE): void {
		state.role = role;
	},
	SET_REFRESH_TOKEN(state, payload: string): void {
		state[REFRESH_TOKEN_KEY] = payload;
	},
	SET_REGISTRATION_DATA(state, student: StudentRegistrationData): void {
		state.registrationData = student;
	},
};

export { mutations };
