// Vendor
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

// Types
import { RESPONSE_FORMAT } from '@/@types/api';
import { AuthResponse, FetchRefreshTokenOptions } from '@/@types/store/auth';

// Costants
import { REJECTED_AUTH_STATUS_CODES } from '@/@constants/api';

// Plugins
import { store } from '@/store';
import { i18n } from '@/translations';

const { locale } = i18n.global;
const { VUE_APP_API_KEY, VUE_APP_API_PROXY_BASE } = process.env || {};

const addTokenToHeader = (config: AxiosRequestConfig, token: string): AxiosRequestConfig => ({
	...config,
	headers: {
		...config.headers,
		Authorization: `Bearer ${token}`,
	},
});

const addApiKeyToHeader = (config: AxiosRequestConfig): AxiosRequestConfig => ({
	...config,
	headers: {
		...config.headers,
		['x-api-key']: VUE_APP_API_KEY,
	},
});

const API = axios.create({
	baseURL: VUE_APP_API_PROXY_BASE,
});

const redoAuthRequest = async (config: AxiosRequestConfig): Promise<AxiosResponse> => {
	const requestConf = {
		...config,
		headers: {
			['Authorization']: `Bearer ${store.getters['auth/token']}`,
		},
	};

	const response = await API.request(requestConf).catch((error) => Promise.reject(error));

	return response;
};

API.interceptors.request.use(async (config) => {
	if (config.token === 'secure') {
		const token = await store.getters['auth/token'];

		config = addTokenToHeader(config, token);
	}

	if (config.token === 'secure_app') {
		const token = await store.getters['auth/appToken'];

		config = addTokenToHeader(config, token);
	}

	if (config.apiKey) {
		config = addApiKeyToHeader(config);
	}

	return config;
});

API.interceptors.response.use(
	(response) => {
		return Promise.resolve(response);
	},
	async (error) => {
		if (error.request.status === 401 && error.config.token === 'secure') {
			const fetchRefreshTokenOptions: FetchRefreshTokenOptions = {
				grant_type: 'refresh_token',
				refresh_token: store.getters['auth/refreshToken'],
				updateState: true,
				mode: error.config.token === 'secure' ? 'student' : 'app',
			};

			const refreshTokenRequest: AuthResponse = await store
				.dispatch('auth/fetchTokens', fetchRefreshTokenOptions)
				.catch(async (error: AxiosError) => {
					if (REJECTED_AUTH_STATUS_CODES.indexOf(error.request.status) !== -1) {
						await store.dispatch('auth/logout', true);
					}

					return Promise.reject(error);
				});

			if (refreshTokenRequest.access_token) {
				const redoRequest: AxiosResponse = await redoAuthRequest(error.config).catch(
					async (error: AxiosError) => {
						if (REJECTED_AUTH_STATUS_CODES.indexOf(error.request.status) !== -1) {
							await store.dispatch('auth/logout', true);
						}

						return Promise.reject(error);
					}
				);

				if (redoRequest.data) {
					return Promise.resolve(redoRequest);
				}
			}
		} else if (error.request.status === 401 && error.config.token === 'secure_app') {
			const tokenRequest = await store.dispatch('auth/fetchTokens', {
				grant_type: 'client_credentials',
				mode: 'app',
				updateState: true,
			});

			if (tokenRequest) {
				const redoRequest = await redoAuthRequest(error.config).catch(
					async (error: AxiosError) => {
						if (REJECTED_AUTH_STATUS_CODES.indexOf(error.request.status) !== -1) {
							await store.dispatch('auth/logout', true);
						}

						return Promise.reject(error);
					}
				);

				if (redoRequest.data) {
					return Promise.resolve(redoRequest);
				}
			}
		}

		return Promise.reject(error.response);
	}
);

const setQueryParams = (
	url: string,
	format?: RESPONSE_FORMAT,
	params?: Record<any, any>
): string => {
	const processedParams = [
		{
			locale: locale.value,
		},
		{
			_format: format || 'usable_json',
		},
		...Object.entries(params || {}).map((entry) => ({
			[entry[0]]: entry[1],
		})),
	];

	return `${url}?${processedParams.reduce((acc: string, value: any, index: number) => {
		const currentValue = Object.entries(value)[0];

		return `${acc}${index > 0 ? '&' : ''}${currentValue[0]}=${currentValue[1]}`;
	}, '')}`;
};

const setDynamicRoute = (endpoint: string, replaceValue: string): string => {
	return endpoint.replace(/ *\{[^)]*\} */g, replaceValue);
};

export { API, setDynamicRoute, setQueryParams };
