// Vendor
import { MutationTree } from 'vuex';

// Types
import {
	AppointmentState,
	CurrentAppointment,
	PutAppointmentResultPayload,
	SetAppointmentListPayload,
	SetAppointmentLoadedPayload,
} from '@/@types/store/appointment';

import { defaultState } from './index';

const mutations: MutationTree<AppointmentState> = {
	RESET_APPOINTMENT_DATA(state: AppointmentState): void {
		Object.assign(state, {
			...defaultState(),
		});
	},
	SET_APPOINTMENT_FEEDBACK(state: AppointmentState, payload: PutAppointmentResultPayload): void {
		state[payload.type].feedback = payload.feedback;
	},
	SET_APPOINTMENT_LIST(state: AppointmentState, payload: SetAppointmentListPayload): void {
		state[payload.type].list = payload.items;
	},
	SET_APPOINTMENT_LOADED(state: AppointmentState, payload: SetAppointmentLoadedPayload): void {
		state[payload.type].loaded = payload.loaded;
	},
	SET_CURRENT_APPOINTMENT(state, payload: CurrentAppointment): void {
		state.current = payload;
	},
	SET_PROCESSING_APPOINTMENT(state, payload: { id: string; add: boolean }): void {
		const { id, add = true } = payload;

		if (add) {
			state.processing.push(id);
		} else {
			state.processing = state.processing.filter((v) => v !== id);
		}
	},
};

export { mutations };
