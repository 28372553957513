// Vendor
import { MutationTree } from 'vuex';

// Types
import { Task, TaskState } from '@/@types/store/task';

const mutations: MutationTree<TaskState> = {
	SET_TASK_LIST(state: TaskState, payload: Task[]): void {
		state.list = payload;
	},
};

export { mutations };
