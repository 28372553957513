// Vendor
import { MutationTree } from 'vuex';

// Types
import { ContentEntry, ContentState } from '@/@types/store/content';

const mutations: MutationTree<ContentState> = {
    SET_CONTENT_DATA(state: ContentState, payload: ContentEntry): void {
        state.data = payload;
    },
    SET_CONTENT_LOADED(state: ContentState, payload: boolean): void {
        state.loaded = payload;
    },
};

export { mutations };
