// Constants
import { CustomRouteInterface } from '@/@types/router';

// Plugins
import { t } from '@/translations';

const taskRoutes: CustomRouteInterface[] = [
	{
		meta: {
			title: 'routes.tasks.title',
		},
		name: 'tabs.tasks',
		component: () => import('@/views/Tasks/Overview.vue'),
		path: t('routes.tasks.slug'),
	},
	{
		meta: {
			backButtonText: 'routes.tasks.title',
			title: 'routes.tasks.title',
		},
		name: 'tabs.tasks.content_page',
		path: `${t('routes.tasks.slug')}/p/:slug`,
		component: () => import('@/views/Content/Single.vue'),
	},
];

export { taskRoutes };
