// Vendor
import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';

// Types
import {
	MediaState,
	PlaylistItems,
	PlaylistResponse,
	YouTubeApiOptions,
	YoutubeVideoDetails,
} from '@/@types/store/media';
import { RootState } from '@/@types/store';

// Plugins
import { API, setDynamicRoute, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { i18n } from '@/translations';
import { store } from '@/store';

const { VUE_APP_YOUTUBE_API_KEY } = process.env;
const { t } = i18n.global;

export const actions: ActionTree<MediaState, RootState> = {
	fetchPlaylist: async ({ dispatch }, options: YouTubeApiOptions): Promise<PlaylistResponse> => {
		if (options.updateState) {
			dispatch('setPlaylistLoaded', false);
		}

		const url: string = setDynamicRoute(
			setQueryParams(ENDPOINTS.YOUTUBE_API, 'json', {
				...options,
				key: VUE_APP_YOUTUBE_API_KEY,
			}),
			options.type
		);

		const request: AxiosResponse<PlaylistResponse> = await API.get(url).catch(
			(error: AxiosResponse) => {
				store.dispatch('userInterface/showToast', {
					color: 'danger',
					text: t('media.playlist.error.fetch'),
				});

				return Promise.reject(error);
			}
		);

		if (request?.data && options.updateState) {
			const { items } = request.data;
			dispatch('setPlaylistItems', items);
			dispatch('setPlaylistLoaded', true);
		}

		return request?.data ?? false;
	},
	getYoutubeVideoDetails: async (_, videoId: string): Promise<YoutubeVideoDetails | false> => {
		const url: string = setDynamicRoute(
			setQueryParams(ENDPOINTS.YOUTUBE_API, 'json', {
				id: videoId,
				part: 'contentDetails',
				key: VUE_APP_YOUTUBE_API_KEY,
			}),
			'videos'
		);

		const result: AxiosResponse = await API.get(url).catch((error: AxiosResponse) => {
			store.dispatch('userInterface/showToast', {
				color: 'danger',
				text: 'Ophalen van de videoinformatie mislukt',
			});

			return Promise.reject(error);
		});

		return result.data.items[0]?.contentDetails || false;
	},
	setPlaylistItems: ({ commit }, items: PlaylistItems): void => {
		return commit('SET_PLAYLIST_ITEMS', items);
	},
	setPlaylistLoaded: ({ commit }, loaded: boolean): void => {
		return commit('SET_PLAYLIST_LOADED', loaded);
	},
	setVideo: ({ commit }, videoId: string | boolean | null): void => {
		return commit('SET_VIDEO', videoId);
	},
};
