const {
	VUE_APP_DRUPAL_API_BASE,
	VUE_APP_API_PROXY_BASE,
	VUE_APP_DRUPAL_DOMAIN,
	VUE_APP_PLAN_RIJLES_API_BASE,
	VUE_APP_YOUTUBE_API_URL,
} = process.env || {};

export const ENDPOINTS = {
	APPOINTMENT: '/appointment',
	AUTHORIZE: `${VUE_APP_PLAN_RIJLES_API_BASE}/authorize`,
	CONTENT: `${VUE_APP_DRUPAL_DOMAIN}/{slug}`,
	DRUPAL_API_BASE: VUE_APP_DRUPAL_API_BASE,
	FLEXPULSE_API_BASE_URL: VUE_APP_API_PROXY_BASE,
	INSTRUCTOR: '/instructor',
	INVOICE_LIST: '/invoice',
	MAIL: `${VUE_APP_DRUPAL_API_BASE}/mail`,
	ORDER: '/order',
	PRODUCTS: '/product',
	PASSWORD: `${VUE_APP_PLAN_RIJLES_API_BASE}/password`,
    POSTCODE_API: 'https://postcode.tech/api/v1/postcode/full',
	PROGRESS_CARD: '/progressCard',
	REMOTE_APP_VERSION: `${VUE_APP_DRUPAL_API_BASE}/wolfroute-version`,
	REGION: '/region',
	STUDENT: '/student',
	TOKEN: '/token',
	VIEWED: `${VUE_APP_DRUPAL_API_BASE}/viewed`,
	YOUTUBE_API: `${VUE_APP_YOUTUBE_API_URL}/{type}`,
};
