// Vendor
import { MutationTree } from 'vuex';

// Types
import { LessonPackage, ProductState } from '@/@types/store/product';

const mutations: MutationTree<ProductState> = {
    SET_CURRENT_PRODUCTS(state: ProductState, payload: LessonPackage[]): void {
        state.current = payload;
    },
    SET_PRODUCTS_LOADED(state: ProductState, payload: boolean): void {
        state.loaded = payload;
    },
};

export { mutations };
