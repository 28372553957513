// Vendor
import { ActionTree } from 'vuex';
import {
	LocalNotifications,
	LocalNotificationSchema,
	PermissionStatus,
} from '@capacitor/local-notifications';

// Types
import {
	NotificationRequestPermissionOptions,
	NotificationState,
} from '@/@types/store/notification';
import { RootState } from '@/@types/store';

const actions: ActionTree<NotificationState, RootState> = {
	cancelLocalNotification: async (_, id: number): Promise<boolean> => {
		await LocalNotifications.cancel({
			notifications: [
				{
					// Force string value to find matching id in queue
					id,
				},
			],
		}).catch((reason) => {
			// Prevent web version from throwing errors
			reason.code === 'UNAVAILABLE' ? Promise.resolve(reason) : Promise.reject(reason);
		});

		return true;
	},
	clearLocalNotificationQueue: async (): Promise<boolean> => {
		const pending = await LocalNotifications.getPending();

		if (pending.notifications.length > 0) {
			await LocalNotifications.cancel({
				notifications: pending.notifications,
			}).catch((reason) => {
				// Prevent web version from throwing errors
				reason.code === 'UNAVAILABLE' ? Promise.resolve(reason) : Promise.reject(reason);
			});
		}

		return true;
	},
	requestPermission: async (
		{ dispatch },
		options: NotificationRequestPermissionOptions
	): Promise<PermissionStatus> => {
		const permission = await LocalNotifications.requestPermissions();

		if (options.updateState) {
			dispatch('setPermission', permission.display);
		}

		return permission;
	},
	scheduleLocalNotifications: async (
		_,
		notifications: LocalNotificationSchema[]
	): Promise<boolean> => {
		await LocalNotifications.schedule({
			notifications,
		}).catch((reason) => {
			// Prevent web version from throwing errors
			reason.code === 'UNAVAILABLE' ? Promise.resolve(reason) : Promise.reject(reason);
		});

		return true;
	},
	setPermission: ({ commit }, payload: boolean): void => {
		return commit('SET_PERMISSION', payload);
	},
};

export { actions };
