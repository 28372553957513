// Vendor
import { RouteRecordRaw } from 'vue-router';

// Constants
import { ROLE } from '@/@constants/auth';

// Plugins
import { t } from '@/translations';

const appointmentRoutes: RouteRecordRaw[] = [
	{
		name: 'tabs.appointment',
		component: () => import('@/views/Appointment/Overview.vue'),
		meta: {
			minRequiredRole: ROLE.ANONYMOUS,
			title: 'routes.appointment_overview.title',
		},
		path: t('routes.appointment_overview.slug'),
	},
	{
		name: 'tabs.appointment.single',
		component: () => import('@/views/Appointment/Single.vue'),
		meta: {
			minRequiredRole: ROLE.STUDENT,
			title: 'routes.appointment_single.title',
		},
		path: t('routes.appointment_single.slug'),
	},
	{
		name: 'tabs.appointment.planner',
		component: () => import('@/views/Appointment/Planner.vue'),
		meta: {
			minRequiredRole: ROLE.STUDENT,
			title: 'routes.appointment_planner.title',
		},
		path: t('routes.appointment_planner.slug'),
	},
];

export { appointmentRoutes };
