// Vendor
import {
	NavigationGuardNext,
	RouteLocationNormalized,
	RouteLocationRaw,
	RouteLocation,
} from 'vue-router';

// Constants
import { APP_LOCALE, DEFAULT_LOCALE } from '@/@constants/locale';
import { DEFAULT_ROUTE } from '@/@constants/app';

// Store
import { store } from '@/store';

class NavigationGuard {
	nextRouteOverride: RouteLocationRaw | undefined = undefined;

	constructor(
		private to: RouteLocationNormalized,
		private from: RouteLocationNormalized,
		private next: NavigationGuardNext,
		private providedDefaultRouteName?: string
	) {}

	get defaultRouteName(): string {
		return this.providedDefaultRouteName || DEFAULT_ROUTE;
	}

	continue(): void {
		return this.nextRouteOverride ? this.next(this.nextRouteOverride) : this.next();
	}

	localeVoter(): this {
		if (
			!Object.values(APP_LOCALE as Record<string, string | string[]>).includes(
				this.to.params.locale
			)
		) {
			/**
			 * @NOTE this hack is needed because Mollie transforms the URL to full lowercase
			 */
			if (
				this.to.params.locale &&
				Object.values(APP_LOCALE as Record<string, string | string[]>).includes(
					this.transformLocale(this.to.params.locale as string)
				)
			) {
				this.nextRouteOverride = {
					...this.to,
					params: {
						...this.to.params,
						locale: this.transformLocale(this.to.params.locale as string),
					},
				};
			} else {
				this.nextRouteOverride = {
					name: this.defaultRouteName,
					params: {
						locale: this.defaultLocale,
					},
				};
			}
		}

		return this;
	}

	transformLocale(locale: string): string {
		return locale
			.split('_')
			.reduce(
				(acc, current, index) =>
					`${acc}${index > 0 ? `_${current.toUpperCase()}` : current}`,
				''
			);
	}

	redirectVoter(): this {
		const { redirect }: { redirect?: string | RouteLocation } = this.to.meta;

		if (!redirect) {
			return this;
		}

		if (typeof redirect === 'string') {
			this.nextRouteOverride = {
				path: redirect,
			};
		} else {
			this.nextRouteOverride = {
				name: <string>redirect.name,
				params: {
					...redirect.params,
				},
			};
		}

		return this;
	}

	securityVoter(): this {
		const { minRequiredRole }: { minRequiredRole?: number } = this.to.meta;

		if (minRequiredRole && minRequiredRole > store.getters['auth/role']) {
			this.nextRouteOverride = {
				name: DEFAULT_ROUTE,
				params: {
					locale: this.defaultLocale,
				},
			};
		}

		return this;
	}

	private get defaultLocale(): string {
		return DEFAULT_LOCALE;
	}

	/**
	 * Process the most important overrides last
	 */
	async processRouteVoters(): Promise<this> {
		await this.localeVoter();
		await this.redirectVoter();
		await this.securityVoter();

		return this;
	}
}

export { NavigationGuard };
