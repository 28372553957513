// Plugins
import { i18n } from '@/translations';
import { store } from '@/store';

// Types
import { RouteChapter, WolfRouteSectionItem, WolfRouteTaskType } from '@/@types/store/wolfroute';
import { Student, StudentApiStorage } from '@/@types/store/student';
import { SelectOption } from '@/@types/components/select';

// Helpers
import { mapExamCity } from '@/helpers/wolfroute';

const { t } = i18n.global;

export const WOLF_ROUTE_FIRST_TASK_KEY = 'first_task';

export const WOLF_ROUTE_PLAY_ICON_ENABLED_ON: Array<WolfRouteTaskType> = ['playlist', 'video'];

export const WOLF_ROUTE_ENABLED_CITIES: Array<string> = [
	'amsterdam',
	'arnhem',
	'barendrecht',
	'breda',
	'den_bosch',
	'den_haag',
	'dordrecht',
	'eindhoven',
	'general',
	'goes',
	'groningen',
	'haarlem',
	'hilversum',
	'leiden',
	'mierlo',
	'middelburg',
	'nijmegen',
	'rotterdam',
	'tilburg',
	'utrecht',
	'vlissingen',
	'zwolle',
];

export const WOLF_ROUTE_CUSTOM_EXAM_CITIES: Array<string> = [
	'amsterdam',
	'arnhem',
	'breda',
	'den_bosch',
	'den_haag',
	'dordrecht',
	'eindhoven',
	'general',
	'groningen',
	'hilversum',
	'rotterdam',
	'tilburg',
	'utrecht',
	'vlissingen',
];

export const REGISTER_CITY_OPTIONS: Array<SelectOption> = [
	{
		label: 'Overig',
		value: 'Overig',
	},
	{
		label: 'Amsterdam',
		value: 'Amsterdam',
	},
	{
		label: 'Arnhem',
		value: 'Arnhem',
	},
	{
		label: 'Barendrecht',
		value: 'Barendrecht',
	},
	{
		label: 'Berkel-Enschot (regio Tilburg)',
		value: 'Tilburg',
	},
	{
		label: 'Breda',
		value: 'Breda',
	},
	{
		label: 'Den Bosch',
		value: 'Den Bosch',
	},
	{
		label: 'Dordrecht',
		value: 'Dordrecht',
	},
	{
		label: 'Eemnes (regio Hilversum)',
		value: 'Hilversum',
	},
	{
		label: 'Eindhoven',
		value: 'Eindhoven',
	},
	{
		label: 'Goes',
		value: 'Goes',
	},
	{
		label: 'Groningen',
		value: 'Groningen',
	},
	{
		label: 'Haarlem',
		value: 'Haarlem',
	},
	{
		label: 'Leiden',
		value: 'Leiden',
	},
	{
		label: 'Middelburg',
		value: 'Middelburg',
	},
	{
		label: 'Mierlo',
		value: 'Mierlo',
	},
	{
		label: 'Nijmegen',
		value: 'Nijmegen',
	},
	{
		label: 'Rijswijk (regio Den Haag)',
		value: 'Den Haag',
	},
	{
		label: 'Rotterdam',
		value: 'Rotterdam',
	},
	{
		label: 'Utrecht',
		value: 'Utrecht',
	},
	{
		label: 'Zoetermeer',
		value: 'Zoetermeer',
	},
	{
		label: 'Zwolle',
		value: 'Zwolle',
	},
];

export const WOLF_ROUTE_DEFAULT_IMG = '/icons/icon_avatar.svg';
export const PROGRESS_DEFAULT_ICON = 'progressie-key';

export enum WOLF_ROUTE_VIDEOS {
	IN_9_STEPS = 'XNJIup_WDug',
	BEFORE_18 = 'dhHnYGDe4Bs',
	CAR = 'XN5I5LfZoBU',
	COACH = 'DGlMuSyZE-Y',
	EXAM = '1451l3MdR2s',
	INTAKE = '27I3eZyd-9s',
	INTRO = '3mStDbDiG1k',
	REFRESH_COURSE = 'lTuqUbTNpw4',
	SWITCH_DRIVING_SCHOOL = '-FThElbL0I8',
	THEORY_EXAM = 'BAF0xmKHIf0',
	THEORY_PACKAGE = 'mypPrDyCVoY',
	TRIAL_EXAM = 'LXBOslzht64',
}

export enum WOLF_ROUTE_PLAYLISTS {
	COMPLEX_SITUATIONS = 'PLNKV1L6Tw9XlUvI0LVLN38tBCMU-ssikQ',
	CONTROL = 'PLNKV1L6Tw9XkNHPlL6vhetTgzPTV2Nvww',
	DRIVING = 'PLNKV1L6Tw9XnPz-tiA7E6JcniVju0rRPx',
	MISTAKES = 'PLNKV1L6Tw9XkNHPlL6vhetTgzPTV2Nvww',
	SIMPLE_SITUATIONS = 'PLNKV1L6Tw9Xl9EusZj3XjE-uhVQf2xKbN',
}

export enum WOLF_ROUTE_EXAM_PLAYLISTS {
	amsterdam = 'PLNKV1L6Tw9XkdxYajCiseLplCnIC0d2dw',
	arnhem = 'PLNKV1L6Tw9XlfKUb2T9JFnCcVkIJ4f6BH',
	breda = 'PLNKV1L6Tw9Xk5jTuDOnR3Z7WvqK5NK5wA',
	den_bosch = 'PLNKV1L6Tw9XnG0fOyT8mzQJBhlaugFETD',
	den_haag = 'PLNKV1L6Tw9XnG9kPyKt90pakAyNDVUeJI',
	dordrecht = 'PLNKV1L6Tw9Xms8qfxWFDA1hCkpiIXEGOK',
	eindhoven = 'PLNKV1L6Tw9Xmz_g231A5xshhKnowC2hkr',
	general = 'PLNKV1L6Tw9XkdxYajCiseLplCnIC0d2dw',
	goes = 'PLNKV1L6Tw9XlmRQ-G_7tMR7FdIRLwW7Jo',
	groningen = 'PLNKV1L6Tw9Xn-B_J-kr6eReE291GPfPQF',
	hilversum = 'PLNKV1L6Tw9Xm1f0RjzXPbCIFCwaSzrC79',
	rotterdam = 'PLNKV1L6Tw9Xkjar96QnaEDkOy9q4iYkhc',
	tilburg = 'PLNKV1L6Tw9XnHkiTJJoV-9fZnbYpRdP2C',
	utrecht = 'PLNKV1L6Tw9XkE7RHi0M2LJ7Aq0xjgbRVM',
	vlissingen = 'PLNKV1L6Tw9XlmRQ-G_7tMR7FdIRLwW7Jo',
}

export const FIRST_TASK_TYPE_ID = WOLF_ROUTE_VIDEOS.INTRO;
export const WOLF_ROUTE_VIEWED_VIDEO_DURATION = 3;

export const WOLF_ROUTE_EXAM_PLAYLIST = (student?: Student): string => {
	if (student) {
		return (WOLF_ROUTE_EXAM_PLAYLISTS as any)[mapExamCity(student)];
	}

	return WOLF_ROUTE_EXAM_PLAYLISTS.general;
};

export const WOLF_ROUTE_GOAL_BLOCK = (apiStorage: StudentApiStorage): WolfRouteSectionItem => {
	switch (apiStorage.doel) {
		case 'overstappen':
			return {
				img: '/img/overstapservice.jpg',
				jewel: store.getters['wolfroute/isTaskDone'](
					'video',
					WOLF_ROUTE_VIDEOS.SWITCH_DRIVING_SCHOOL
				)
					? 'task_completed'
					: 'task_not_completed',
				taskType: 'video',
				text: 'wolfroute.goal.switch.title',
				videoId: WOLF_ROUTE_VIDEOS.SWITCH_DRIVING_SCHOOL,
			};
		case 'rijvaardigheid':
			return {
				img: '/img/opfris.jpg',
				jewel: store.getters['wolfroute/isTaskDone'](
					'video',
					WOLF_ROUTE_VIDEOS.REFRESH_COURSE
				)
					? 'task_completed'
					: 'task_not_completed',
				taskType: 'video',
				text: 'wolfroute.goal.refresh_course.title',
				videoId: WOLF_ROUTE_VIDEOS.REFRESH_COURSE,
			};
		case 'voor-18e':
			return {
				img: '/img/voor-18.jpg',
				jewel: store.getters['wolfroute/isTaskDone']('video', WOLF_ROUTE_VIDEOS.BEFORE_18)
					? 'task_completed'
					: 'task_not_completed',
				taskType: 'video',
				text: 'wolfroute.goal.before_18.title',
				videoId: WOLF_ROUTE_VIDEOS.BEFORE_18,
			};
		case 'geen':
		default:
			return {
				img: '/img/7-stappen.jpg',
				jewel: store.getters['wolfroute/isTaskDone']('video', WOLF_ROUTE_VIDEOS.IN_9_STEPS)
					? 'task_completed'
					: 'task_not_completed',
				taskType: 'video',
				text: 'wolfroute.goal.general.title',
				videoId: WOLF_ROUTE_VIDEOS.IN_9_STEPS,
			};
	}
};

export const ROUTE_CHAPTERS = (): RouteChapter[] => {
	if (!store.getters['progress/cardEntries'].length) {
		return [];
	}

	return [
		{
			title: t('wolfroute.route.1.title'),
			icon: '/icons/route/start-flag.svg',
			color: 'primary',
			completed: store.getters['wolfroute/isVideoWatched'](WOLF_ROUTE_VIDEOS.INTRO) ? 1 : 0,
			items: [
				{
					description: t('wolfroute.route.1.title'),
				},
				{
					description: t('wolfroute.route.1.video'),
					description_link: WOLF_ROUTE_VIDEOS.INTRO,
					videoOnly: true,
				},
			],
		},
		{
			title: t('wolfroute.route.2.title'),
			officialTitle: store.getters['progress/cardCategoryByNumber'](1)[0].description,
			icon: '/icons/route/steering-wheel.svg',
			color: 'primary',
			completed: store.getters['progress/cardCompletedByCategory'](1).length,
			items: store.getters['progress/cardCategoryByNumber'](1),
		},
		{
			title: t('wolfroute.route.3.title'),
			officialTitle: store.getters['progress/cardCategoryByNumber'](2)[0].description,
			icon: '/icons/route/car.svg',
			color: 'primary',
			completed: store.getters['progress/cardCompletedByCategory'](2).length,
			items: store.getters['progress/cardCategoryByNumber'](2),
		},
		{
			title: t('wolfroute.route.4.title'),
			officialTitle: store.getters['progress/cardCategoryByNumber'](3)[0].description,
			icon: '/icons/route/crossroad.svg',
			color: 'primary',
			completed: store.getters['progress/cardCompletedByCategory'](3).length,
			items: store.getters['progress/cardCategoryByNumber'](3),
		},
		{
			title: t('wolfroute.route.5.title'),
			officialTitle: store.getters['progress/cardCategoryByNumber'](4)[0].description,
			icon: '/icons/route/traffic-light.svg',
			color: 'primary',
			completed: store.getters['progress/cardCompletedByCategory'](4).length,
			items: store.getters['progress/cardCategoryByNumber'](4),
		},
		{
			title: t('wolfroute.route.6.title'),
			icon: '/icons/route/hat.svg',
			color: 'secondary',
			completed: store.getters['student/hasTheory'] ? 1 : 0,
			items: [
				{
					description: t('wolfroute.route.6.title'),
				},
				{
					description: 'Theorie pakket',
					description_link: WOLF_ROUTE_VIDEOS.THEORY_PACKAGE,
				},
			],
		},
		{
			title: t('wolfroute.route.7.title'),
			icon: '/icons/route/car-slope.svg',
			color: 'secondary',
			completed: store.getters['appointment/hasAppointmentType'](
				'tussentijdse toets',
				'agenda'
			)
				? 1
				: 0,
			items: [
				{
					description: t('wolfroute.route.7.title'),
				},
				{
					description: t('wolfroute.route.7.video'),
					description_link: WOLF_ROUTE_VIDEOS.TRIAL_EXAM,
					videoOnly: true,
				},
			],
		},
		{
			title: t('wolfroute.route.8.title'),
			icon: '/icons/route/finish-flag.svg',
			color: 'secondary',
			completed: store.getters['student/graduated'] ? 1 : 0,
			items: [
				{
					description: t('wolfroute.route.8.title'),
				},
				{
					description: t('wolfroute.route.8.video'),
					description_link: WOLF_ROUTE_VIDEOS.EXAM,
					videoOnly: true,
				},
			],
		},
	];
};
