const delay = (timeout: number, value: any): Promise<void> => {
    return new Promise(function(resolve) {
        if (timeout <= 0) {
            resolve(value);
        } else {
            setTimeout(resolve.bind(null, value), timeout);
        }
    });
};

export const iterateSerialWithTimeout = (array: Array<any>, timeout: number, callback: (any)): Promise<any> => {
    if (!callback) {
        return Promise.resolve(false);
    }

    return array.reduce((promise, item, index) => {
        return promise.then(() => {
            const delayTime = index === 0 ? 0 : timeout;

            return delay(delayTime, item).then(callback)
        });
    }, Promise.resolve());
};

export default iterateSerialWithTimeout;
