// Vendor
import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';

// Types
import { FetchInstructorOptions, InstructorState, Instructor } from '@/@types/store/instructor';
import { RootState } from '@/@types/store';

// Plugins
import { API, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { i18n } from '@/translations';

const { t } = i18n.global;

const actions: ActionTree<InstructorState, RootState> = {
	fetchInstructorData: async (
		{ dispatch },
		options: FetchInstructorOptions
	): Promise<Instructor | boolean> => {
		const url = setQueryParams(ENDPOINTS.INSTRUCTOR, 'json', {
			id: options.id,
		});

		if (options.updateState) {
			await dispatch('setInstructorLoaded', false);
		}

		const request: AxiosResponse = await API.get(url, {
			apiKey: true,
			token: 'secure',
		}).catch((error) => {
			dispatch(
				'userInterface/showToast',
				{
					color: 'danger',
					text: t('instructor.error.fetch'),
				},
				{ root: true }
			);

			return Promise.reject(error);
		});

		if (request?.data && options.updateState) {
			const { result } = request.data;

			await dispatch('setInstructorData', result[0]);
			await dispatch('setInstructorLoaded', true);
		}

		return request?.data?.result[0] || false;
	},
	resetInstructor: ({ commit }): void => {
		return commit('RESET_INSTRUCTOR');
	},
	setInstructorData: ({ commit }, payload: Instructor): void => {
		return commit('SET_INSTRUCTOR_DATA', payload);
	},
	setInstructorLoaded: ({ commit }, payload: boolean): void => {
		return commit('SET_INSTRUCTOR_LOADED', payload);
	},
};

export { actions };
