// Vendor
import { MutationTree } from 'vuex';

// Types
import { ProgressCardEntries, ProgressState } from '@/@types/store/progress';

const mutations: MutationTree<ProgressState> = {
    SET_PROGRESS_CARD_ENTRIES (state: ProgressState, payload: ProgressCardEntries): void {
        state.card.entries = payload;
    },
    SET_PROGRESS_CARD_LOADED (state: ProgressState, payload: boolean): void {
        state.card.loaded = payload;
    }
};

export { mutations };
