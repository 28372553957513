import { CoreAction } from '@/@types/store/app';

const DEFAULT_ROUTE = 'tabs.route';

const CORE_ACTIONS: CoreAction[] = [
	{
		name: 'auth/fetchTokens',
		options: {
			grant_type: 'client_credentials',
			mode: 'app',
			updateState: true,
		},
	},
	{
		name: 'wolfroute/setFirstTaskFromStorage',
	},
	{
		name: 'notification/clearLocalNotificationQueue',
	},
	{
		auth: true,
		name: 'student/fetchStudentData',
		options: {
			fetchInstructor: true,
			fetchViewedData: true,
		},
	},
	{
		auth: true,
		name: 'appointment/fetchAppointmentData',
		options: {
			fetchProgressCard: true,
			query: {
				availableHours: 0,
			},
			scheduleNotifications: true,
			type: 'agenda',
		},
	},
	{
		name: 'progress/fetchProgressCard',
		options: {
			query: {
				getProgCardOrLesson: 0,
				getLatestLesson: 1,
			},
			setHomeworkNotifications: true,
			updateState: true,
		},
	},
	{
		name: 'app/setCanShare',
	},
	{
		name: 'app/fetchLocalAppVersion',
	},
];

const RESET_ACTIONS: CoreAction[] = [
	{
		name: 'student/resetStudent',
	},
	{
		name: 'instructor/resetInstructor',
	},
	{
		name: 'wolfroute/resetViewed',
	},
	{
		name: 'appointment/resetAppointmentData',
	},
];

const GTM_AFFILIATION = 'WOLF Route';

export { CORE_ACTIONS, DEFAULT_ROUTE, GTM_AFFILIATION, RESET_ACTIONS };
