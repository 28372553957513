// Vendor
import { ActionTree } from 'vuex';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { DynamicURL } from '@netvlies/utility-collection';

// Types
import { FetchStudentOptions, StudentState, UpdateStudentOptions } from '@/@types/store/student';
import { Student } from '@/@types/store/student';
import { FetchInstructorOptions } from '@/@types/store/instructor';
import { FetchViewedDataOptions } from '@/@types/store/wolfroute';
import { RootState } from '@/@types/store';
import { FlexpulseResponse } from '@/@types/api';
import { FetchTokenOptions, GrantType } from '@/@types/store/auth';

// Constants
import { REJECTED_AUTH_STATUS_CODES } from '@/@constants/api';
import { ROLE } from '@/@constants/auth';

// Plugins
import { API } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { i18n } from '@/translations';

const { t } = i18n.global;

const actions: ActionTree<StudentState, RootState> = {
	fetchStudentData: async (
		{ dispatch },
		options: FetchStudentOptions
	): Promise<Student | boolean> => {
		const {
			updateState,
			fetchInstructor,
			fetchViewedData,
			email,
			showToasts = true,
		} = options || {};
		const url = new DynamicURL(ENDPOINTS.STUDENT).setQueryParams({
			_format: 'json',
			email,
		});

		const config: AxiosRequestConfig = {
			apiKey: true,
			token: email ? 'secure_app' : 'secure',
		};

		if (updateState) {
			await dispatch('setStudentLoaded', false);
		}

		const response: AxiosResponse<FlexpulseResponse<Student[]>> = await API.get(
			url.resolve(),
			config
		).catch((error: AxiosResponse) => {
			if (REJECTED_AUTH_STATUS_CODES.indexOf(error.status) === -1 && showToasts) {
				dispatch(
					'userInterface/showToast',
					{
						color: 'danger',
						text: t('student.error.fetch'),
					},
					{
						root: true,
					}
				);
			}

			return Promise.reject(error);
		});

		const { result } = response.data;

		if (result === null) {
			return false;
		}

		const student = result[0] || {};

		if (fetchInstructor && student.instructor) {
			const instructorDataOptions: FetchInstructorOptions = {
				id: student.instructor,
				updateState: true,
			};

			await dispatch('instructor/fetchInstructorData', instructorDataOptions, {
				root: true,
			});
		}

		if (fetchViewedData) {
			const viewedDataOptions: FetchViewedDataOptions = {
				student,
				updateState: true,
			};

			await dispatch('wolfroute/fetchViewedData', viewedDataOptions, {
				root: true,
			});
		}

		await dispatch('setStudentData', result[0]);
		await dispatch('setStudentLoaded', true);

		return result[0];
	},
	updateStudent: async (
		{ dispatch },
		options: UpdateStudentOptions
	): Promise<Student | boolean> => {
		const { updateState, data, id, showToasts = true, authorize = false } = options || {};
		const url = new DynamicURL(ENDPOINTS.STUDENT).setQueryParams({
			_format: 'json',
		});

		const config: AxiosRequestConfig = {
			apiKey: true,
			token: id ? 'secure_app' : 'secure',
		};

		if (updateState) {
			dispatch('setStudentLoaded', false);
		}

		const response: AxiosResponse<FlexpulseResponse<Student[]>> = await API.put(
			url.resolve(),
			{
				...data,
				id,
			},
			config
		).catch((error: AxiosResponse) => {
			if (REJECTED_AUTH_STATUS_CODES.indexOf(error.status) === -1 && showToasts) {
				dispatch(
					'userInterface/showToast',
					{
						color: 'danger',
						text: t('student.error.fetch'),
					},
					{
						root: true,
					}
				);
			}

			return Promise.reject(error);
		});

		const { result } = response.data;

		if (result === null) {
			return false;
		}

		const student = result[0] || result;

		if (id && student && student.authorization_code && authorize) {
			const authTokenSettings: FetchTokenOptions = {
				code: student.authorization_code,
				grant_type: 'authorization_code',
				updateState: true,
			};

			await dispatch('auth/fetchTokens', authTokenSettings, {
				root: true,
			});

			await dispatch('app/fetchAppCoreData', true, {
				root: true,
			});

			await dispatch('auth/setRole', ROLE.STUDENT, {
				root: true,
			});
		}

		await dispatch('setStudentData', student);
		if (updateState) dispatch('setStudentLoaded', true);

		return student;
	},
	resetStudent: ({ commit }): void => {
		commit('RESET_STUDENT');
	},
	setCanPlan: ({ commit }, canPlan: boolean | null): void => {
		commit('SET_CAN_PLAN', canPlan);
	},
	setStudentData: ({ commit }, payload: Student): void => {
		commit('SET_STUDENT_DATA', payload);
	},
	setStudentLoaded: ({ commit }, payload: boolean): void => {
		commit('SET_STUDENT_LOADED', payload);
	},
};

export { actions };
