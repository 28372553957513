// Vendor
import { Module } from 'vuex';

// Types
import { AppointmentState } from '@/@types/store/appointment';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): AppointmentState => ({
	agenda: {
		list: [],
		loaded: false,
	},
	availableAppointments: {
		list: [],
		loaded: false,
	},
	current: {
		data: {
			firstBlock: null,
			secondBlock: null,
		},
		loaded: false,
	},
	planQueue: {
		feedback: {
			errorResponses: [],
			successResponses: [],
		},
		list: [],
		loaded: false,
	},
	processing: [],
});

const appointment: Module<AppointmentState, RootState> = {
	namespaced: true,
	state: defaultState(),
	actions,
	getters,
	mutations,
};

export { appointment };
