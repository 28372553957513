import app from './src/app.json';
import account from './src/account.json';
import appointment from './src/appointment.json';
import auth from './src/auth.json';
import city from './src/city.json';
import contact from './src/contact.json';
import content from './src/content.json';
import error from './src/error.json';
import form from './src/form.json';
import general from './src/general.json';
import greeting from './src/greeting.json';
import invoice from './src/invoice.json';
import media from './src/media.json';
import order from './src/order.json';
import progress from './src/progress.json';
import product from './src/product.json';
import routes from './src/routes.json';
import student from './src/student.json';
import success from './src/success.json';
import wolfroute from './src/wolfroute.json';
import video from './src/video.json';
import tasks from './src/tasks.json';
import trial from './src/trial.json';

export default {
	app,
	account,
	appointment,
	auth,
	city,
	contact,
	content,
	error,
	form,
	general,
	greeting,
	invoice,
	media,
	order,
	progress,
	product,
	routes,
	student,
	success,
	wolfroute,
	video,
	tasks,
	trial,
};
