// Vendor
import { GetterTree } from 'vuex';

// Types
import {
	WolfRouteSection,
	WolfRouteSectionItem,
	WolfRouteState,
	WolfRouteTaskType,
} from '@/@types/store/wolfroute';
import { Student } from '@/@types/store/student';
import { RootState } from '@/@types/store';

// Constants
import { WOLF_ROUTE_FIRST_TASK_KEY } from '@/@constants/wolfroute';

const getters: GetterTree<WolfRouteState, RootState> = {
	isFirstTaskDone: (state): boolean =>
		state.firstTaskInStorage ||
		state?.viewed['task']?.[WOLF_ROUTE_FIRST_TASK_KEY] !== undefined,
	isTaskDone:
		(state) =>
		(type: WolfRouteTaskType, id: string): boolean =>
			state.viewed[type]?.[id] !== undefined,
	sectionTasksBySlugDone:
		(_, getters: any) =>
		(slug: string, student?: Student): boolean => {
			const tasks: WolfRouteSection = getters.sectionBySlug(slug, student);

			return tasks.items
				.filter((item: WolfRouteSectionItem) => item.taskType !== undefined)
				.every((item: WolfRouteSectionItem) => item.jewel === 'task_completed');
		},
	isVideoWatched:
		(state) =>
		(code: string): boolean =>
			state.viewed.video != undefined && code in state.viewed.video
				? Number(state.viewed?.video?.[code].counter) > 0
				: false,
};

export { getters };
