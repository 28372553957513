// Vendor
import { MutationTree } from 'vuex';

// Types
import { UserInterfaceState } from '@/@types/store/userInterface';

const mutations: MutationTree<UserInterfaceState> = {
    SET_DARK_MODE (state: UserInterfaceState, payload: boolean): void {
        state.darkMode = payload;
    }
};

export { mutations };
