export const capitalize = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

export const createSlug = (text: string): string =>
	text
		.toLowerCase()
		.trim()
		.replace(/[^a-zA-Z0-9\-_\s]/gi, '')
		.replace(/\s+/g, '-');

export const filterTextBySymbol = (string: string, symbol: string, returnPosition = 0): string =>
	string.indexOf(symbol) !== -1 ? string.split(symbol)[returnPosition] : string;

export const stripChapterNumbers = (description: string): string => {
	const task = /(\d\.\d*\s*)(.*)/.exec(description);

	return task instanceof Array ? task[2] : description;
};

export const getInitials = (firstName: string, lastName: string) =>
	`${firstName.substring(0, 1).toUpperCase()}.${lastName.substring(0, 1).toUpperCase()}.`;
