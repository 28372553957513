// Vendor
import { Module } from 'vuex';

// Types
import { ProductState } from '@/@types/store/product';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): ProductState => ({
    current: undefined,
    loaded: false,
});


const product: Module<ProductState, RootState> = {
    namespaced: true,
    state: defaultState(),
    actions,
    getters,
    mutations,
};

export { product };
