// Vendor
import { GetterTree } from 'vuex';

// Types
import { TaskState } from '@/@types/store/task';
import { RootState } from '@/@types/store';

const getters: GetterTree<TaskState, RootState> = {
	list: (state) => state.list,
};

export { getters };
