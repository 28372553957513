// Vendor
import { Module } from 'vuex';

// Types
import { InstructorState } from '@/@types/store/instructor';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): InstructorState => ({
    data: {
        category: [''],
        firstName: '',
        id: 0,
        lastName: '',
        plannerId: 0,
        plannerName: '',
        studentSelfPlan: 0,
    },
    loaded: false,
});

const instructor: Module<InstructorState, RootState> = {
    namespaced: true,
    state: defaultState(),
    actions,
    getters,
    mutations,
};

export { instructor };
