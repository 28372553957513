// Vendor
import { Module } from 'vuex';

// Types
import { AuthState } from '@/@types/store/auth';
import { RootState } from '@/@types/store';

// Constants
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, ROLE } from '@/@constants/auth';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): AuthState => ({
	[ACCESS_TOKEN_KEY]: '',
	[REFRESH_TOKEN_KEY]: '',
	appTokens: {
		[ACCESS_TOKEN_KEY]: '',
		[REFRESH_TOKEN_KEY]: '',
	},
	registrationData: null,
	role: ROLE.ANONYMOUS,
});

const auth: Module<AuthState, RootState> = {
	namespaced: true,
	state: defaultState(),
	actions,
	getters,
	mutations,
};

export { auth };
