// Vendor
import { GetterTree } from 'vuex';

// Types
import { AuthState } from '@/@types/store/auth';
import { RootState } from '@/@types/store';
import { StudentRegistrationData } from '@/@types/store/student';

// Constants
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, ROLE } from '@/@constants/auth';

const getters: GetterTree<AuthState, RootState> = {
	isLoggedIn: (state): boolean => state.role > ROLE.ANONYMOUS,
	role: (state): ROLE => state.role,
	refreshToken: (state): string => state[REFRESH_TOKEN_KEY] || '',
	registrationData: (state): StudentRegistrationData | null => state.registrationData,
	token: (state): string => state[ACCESS_TOKEN_KEY] || '',
	appToken: (state): string => state.appTokens[ACCESS_TOKEN_KEY] || '',
};

export { getters };
