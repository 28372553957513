import { AppointmentType } from '@/@types/store/appointment';

enum APPOINTMENT {
	ANXIETY_EXAM = 'overige19',
	BNOR_EXAM = 'overige21',
	BNOR_ANXIETY_EXAM = 'overige22',
	EXAM = 'overige17',
	INTAKE = 'intake',
	LESSON = 'lesuur',
	TT = 'tussentijdse toets',
}

const DEFAULT_NOTIFICATION_OFFSET_HOURS = 2;
const PROTECTED_APPOINTMENT_TYPES: AppointmentType[] = [
	'intake',
	'overige17',
	'overige19',
	'overige21',
	'overige22',
];
const PROTECTED_APPOINTMENT_COLOR_CODES: Array<number> = [121];
const REQUIRED_HOUR_DIFF = 48;

// Planner limit changed: https://netvlies.zendesk.com/agent/tickets/23171
enum PLANNER_SETTINGS {
	BALANCE_NOTICE_TRESHOLD = 4,
	DEFAULT_DAY_OFFSET = 0,
	DEFAULT_WEEK_OFFSET = 0,
	MAX_TRIES_WEEKLY_APPOINTMENTS = 14,
}

export {
	APPOINTMENT,
	DEFAULT_NOTIFICATION_OFFSET_HOURS,
	PROTECTED_APPOINTMENT_COLOR_CODES,
	PROTECTED_APPOINTMENT_TYPES,
	REQUIRED_HOUR_DIFF,
	PLANNER_SETTINGS,
};
