// Vendor
import { GetterTree } from 'vuex';

// Types
import {
	PackageAdvice,
	Student,
	StudentApiStorage,
	StudentAppointmentBalances,
	StudentState,
} from '@/@types/store/student';
import { RootState } from '@/@types/store';

const getters: GetterTree<StudentState, RootState> = {
	apiStorage: (state): StudentApiStorage =>
		typeof state.data.apiStorage === 'string'
			? JSON.parse(state.data.apiStorage)
			: {
					avatarId: '3',
					doel: 'geen',
			  },
	appointmentBalances: (state, _, __, rootGetters: any): StudentAppointmentBalances => {
		return {
			display: {
				minimumBalance: rootGetters['appointment/minutesToLessons'](
					state.data.balances?.minimumMinuteBalance || 0
				),
				plannable: rootGetters['appointment/minutesToLessons'](
					state.data.balances?.minuteBalance || 0
				),
				planned: rootGetters['appointment/minutesToLessons'](
					state.data.balances?.minuteBalance_planned || 0
				),
				total:
					rootGetters['appointment/minutesToLessons'](
						rootGetters['appointment/listDuration']('agenda', 'lesuur') || 0
					) +
					rootGetters['appointment/minutesToLessons'](
						state.data.balances?.minuteBalance || 0
					),
			},
			raw: {
				minimumBalance: state.data.balances?.minimumMinuteBalance || 0,
				plannable: state.data.balances?.minuteBalance || 0,
				planned: state.data.balances?.minuteBalance_planned || 0,
				total:
					rootGetters['appointment/listDuration']('agenda', 'lesuur') ||
					0 + (state.data.balances?.minuteBalance || 0),
			},
		};
	},
	canPlan: (state): boolean => state.canPlan ?? state.data?.type === 'full',
	data: (state): Student => state.data,
	advice: (state): PackageAdvice | undefined => state.data?.advice,
	graduated: (state): boolean => (state.data.dateGraduated ? true : false),
	hasTheory: (state): boolean => (state.data.dateOfTheory ? true : false),
	isAuthorized: (state): boolean => !!state.data.cbrAuthorized,
};

export { getters };
