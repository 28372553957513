// Vendor
import { Module } from 'vuex';

// Types
import { TaskState } from '@/@types/store/task';
import { RootState } from '@/@types/store';

// Constants
import { STATIC_TASKS } from '@/@constants/tasks';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): TaskState => ({
	list: STATIC_TASKS,
});

const task: Module<TaskState, RootState> = {
	namespaced: true,
	state: defaultState(),
	actions,
	getters,
	mutations,
};

export { task };
