// Vendor
import dayjs from 'dayjs';

// Plugins
import { i18n } from '@/translations';

const DEFAULT_DATE_FORMAT =
	dayjs.Ls[`${i18n.global.locale.value.split('_')[0]}`]?.formats?.L || 'en';
const FULL_DATE_FORMAT = 'DD MMMM YYYY';
const EXTENDED_DATE_FORMAT = 'dd DD MMMM YYYY';

export { DEFAULT_DATE_FORMAT, FULL_DATE_FORMAT, EXTENDED_DATE_FORMAT };
