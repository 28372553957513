// Vendor
import { ActionTree } from 'vuex';

// Constants
import {
	STATIC_TASKS,
	TRIAL_LESSON_TASK,
	THEORY_TASK,
	THEORY_DATE_TASK,
	AUTHORIZE_TASK,
} from '@/@constants/tasks';

// Types
import { Task, TaskState } from '@/@types/store/task';
import { RootState } from '@/@types/store';

const actions: ActionTree<TaskState, RootState> = {
	updateTasks: ({ dispatch, rootGetters }): void => {
		const tasks: Task[] = [];
		tasks.push(...STATIC_TASKS);

		if (!rootGetters['appointment/hasAppointmentType']('intake', 'agenda')) {
			tasks.push(TRIAL_LESSON_TASK);
		}

		if (!rootGetters['student/hasTheory']) {
			tasks.push(THEORY_TASK);
			tasks.push(THEORY_DATE_TASK);
		}

		if (!rootGetters['student/isAuthorized']) {
			tasks.push(AUTHORIZE_TASK);
		}

		dispatch('setTaskList', tasks);
	},
	setTaskList: ({ commit }, payload: Task[]): void => {
		return commit('SET_TASK_LIST', payload);
	},
};

export { actions };
