export default {
    currency: {
        currency: 'EUR',
        style: 'currency'
    },
    currencyLong: {
        currency: 'EUR',
        currencyDisplay: 'name',
        style: 'currency'
    },
    currencyShort: {
        currency: 'EUR',
        currencyDisplay: 'name',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency'
    }
}
