// Vendor
import { MutationTree } from 'vuex';

// Types
import { WolfRouteState } from '@/@types/store/wolfroute';

import { defaultState } from './index';

const mutations: MutationTree<WolfRouteState> = {
	RESET_VIEWED_DATA(state: WolfRouteState): void {
		Object.assign(state, {
			...defaultState(),
		});
	},
	SET_VIEWED_DATA(state: WolfRouteState, payload: any): void {
		state.viewed = payload;
	},
	SET_FIRST_TASK_FROM_STORAGE(state: WolfRouteState, paylaod: boolean): void {
		state.firstTaskInStorage = paylaod;
	},
};

export { mutations };
