// Vendor
import { Module } from 'vuex';

// Types
import { NotificationState } from '@/@types/store/notification';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): NotificationState => ({
    permission: 'prompt'
});

const notification: Module<NotificationState, RootState> = {
    namespaced: true,
    state: defaultState(),
    actions,
    getters,
    mutations,
};

export { notification };
