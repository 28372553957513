// Vendor
import { GetterTree } from 'vuex';

// Types
import { UserInterfaceState } from '@/@types/store/userInterface';
import { RootState } from '@/@types/store';

const getters: GetterTree<UserInterfaceState, RootState> = {
    isDarkMode: (state): boolean => state.darkMode || false
};

export { getters };
