// Vendor
import { Module } from 'vuex';

// Types
import { WolfRouteState } from '@/@types/store/wolfroute';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): WolfRouteState => ({
	firstTaskInStorage: false,
	sections: [],
	viewed: {
		milestone: {},
		playlist: {},
		task: {},
		video: {},
	},
});

const wolfroute: Module<WolfRouteState, RootState> = {
	namespaced: true,
	state: defaultState(),
	actions,
	getters,
	mutations,
};

export { wolfroute };
