// Vendor
import { ActionTree } from 'vuex';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

// Types
import { FetchProductOptions, ProductState, LessonPackage } from '@/@types/store/product';
import { RootState } from '@/@types/store';

// Plugins
import { API, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { store } from '@/store';
import { i18n } from '@/translations';

const { t } = i18n.global;

const actions: ActionTree<ProductState, RootState> = {
	fetchProducts: async (
		{ dispatch, rootGetters },
		options: FetchProductOptions
	): Promise<LessonPackage[] | boolean> => {
		const { updateState = true, productCategoryId, productId: id } = options || {};

		const url = setQueryParams(ENDPOINTS.PRODUCTS, 'json', {
			...(productCategoryId && { productCategoryId }),
			...(id && { id }),
		});

		if (updateState) {
			await dispatch('setProductLoaded', false);
		}

		const config: AxiosRequestConfig = {
			apiKey: true,
			token: rootGetters['auth/isLoggedIn'] ? 'secure' : 'secure_app',
		};

		const response: AxiosResponse = await API.get(url, config).catch((error: AxiosResponse) => {
			store.dispatch('userInterface/showToast', {
				color: 'danger',
				text: t('wolfroute.package.error.fetch'),
			});

			return Promise.reject(error);
		});

		if (response?.data && updateState) {
			const { result } = response.data;

			await dispatch('setProductData', result);
		}

		await dispatch('setProductLoaded', true);

		return response?.data?.result || false;
	},
	setProductData: ({ commit }, payload: LessonPackage[]): void => {
		return commit('SET_CURRENT_PRODUCTS', payload);
	},
	setProductLoaded: ({ commit }, payload: boolean): void => {
		return commit('SET_PRODUCTS_LOADED', payload);
	},
};

export { actions };
