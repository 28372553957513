// Vendor
import { Module } from 'vuex';

// Types
import { ContentState } from '@/@types/store/content';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): ContentState => ({
    data: {
        changed: [],
        created: [],
        field_content: [],
        nid: [],
        path: [],
        title: []
    },
    loaded: false,
});

const content: Module<ContentState, RootState> = {
    namespaced: true,
    state: defaultState(),
    actions,
    getters,
    mutations,
};

export { content };
