// Vendor
import { GetterTree } from 'vuex';

// Types
import { MediaState, PlaylistItems, PlaylistState } from '@/@types/store/media';
import { RootState } from '@/@types/store';

// Helpers
import { filterTextBySymbol } from '@/helpers/text';

const getters: GetterTree<MediaState, RootState> = {
    playlist: (state): PlaylistState => state.playlist || {
        items: [],
        loaded: false
    },
    playlistItems: (state): PlaylistItems => state.playlist.items || [],
    playlistLoaded: (state): boolean => state.playlist.loaded || false,
    playlistItemNiceTitle: () => (title: string): string => filterTextBySymbol(filterTextBySymbol(title, '|', 1), '. ', 1),
    playlistWatched: () => (watchedVideos: Array<any>, totalVideos: Array<any>): boolean => watchedVideos.length === totalVideos.length,
    video: (state): string | null | boolean => state.video || null
};

export { getters };
