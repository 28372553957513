// Vendor
import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';

// Types
import { ContentEntry, ContentState, FetchContentOptions } from '@/@types/store/content';
import { RootState } from '@/@types/store';

// Plugins
import { API, setDynamicRoute, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { store } from '@/store';
import { i18n } from '@/translations';

const { t } = i18n.global;

const actions: ActionTree<ContentState, RootState> = {
	fetchContentPage: async (
		{ dispatch },
		options: FetchContentOptions
	): Promise<ContentEntry | boolean> => {
		const url = setQueryParams(setDynamicRoute(ENDPOINTS.CONTENT, options.slug), 'usable_json');

		if (options.updateState) {
			dispatch('setContentLoaded', false);
		}

		const request: AxiosResponse = await API.get(url, {
			apiKey: true,
		}).catch((error) => {
			const feedback =
				error.status === 404 ? 'content.error.not_found' : 'content.error.fetch';

			store.dispatch('userInterface/showToast', {
				color: 'danger',
				text: t(feedback),
			});

			return Promise.reject(error);
		});

		if (request?.data && options.updateState) {
			dispatch('setContentData', request.data);
			dispatch('setContentLoaded', true);
		}

		return request?.data ?? false;
	},
	setContentData: ({ commit }, payload: ContentEntry): void => {
		return commit('SET_CONTENT_DATA', payload);
	},
	setContentLoaded: ({ commit }, payload: boolean): void => {
		return commit('SET_CONTENT_LOADED', payload);
	},
};

export { actions };
