// Vendor
import { MutationTree } from 'vuex';

// Types
import { AppState, RemoteAppData } from '@/@types/store/app';

const mutations: MutationTree<AppState> = {
	SET_APP_LOADED(state: AppState, payload: boolean): void {
		state.loaded = payload;
	},
	SET_CAN_SHARE(state: AppState, payload: boolean): void {
		state.canShare = payload;
	},
	SET_LOCAL_APP_VERSION: (state, version: string): void => {
		state.version = version;
	},
	SET_REMOTE_APP: (state, remote: RemoteAppData): void => {
		state.remote = remote;
	},
};

export { mutations };
