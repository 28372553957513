// Vendor
import { RouteRecordRaw } from 'vue-router';

// Plugins
import { t } from '@/translations';

const authRoutes: RouteRecordRaw[] = [
	{
		meta: {
			title: 'routes.auth.register.title',
		},
		name: 'tabs.more.register',
		path: `${t('routes.more.slug')}/${t('routes.auth.register.slug')}`,
		component: () => import('@/views/Auth/Register/Form.vue'),
	},
];

export { authRoutes };
