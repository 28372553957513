// Vendor
import { GetterTree } from 'vuex';

// Types
import { ProgressCardEntries, ProgressCardEntry, ProgressState } from '@/@types/store/progress';
import { RootState } from '@/@types/store';

// Helpers
import { removeDuplicateEntries } from '@/helpers/array';

const getters: GetterTree<ProgressState, RootState> = {
	cardCategoryByNumber:
		(state) =>
		(number: number): ProgressCardEntries =>
			state.card.entries.filter(
				(entry: ProgressCardEntry) =>
					parseInt(entry.description.slice(0, 1)) === number ?? 0
			) || [],
	cardCategoryTitleByNumber:
		(state) =>
		(number: number): string | undefined =>
			state.card.entries.find((card) => Number(card.description?.charAt(0)) === number)
				?.description,
	cardCompletedByCategory:
		(state) =>
		(number: number): ProgressCardEntries =>
			state.card.entries.filter(
				(entry: ProgressCardEntry) =>
					parseInt(entry.description.slice(0, 1)) === number &&
					(entry.result == '3' || entry.result == '4')
			) ?? (() => []),
	cardCategories: (state): ProgressCardEntries =>
		state.card.entries.filter(
			(entry: ProgressCardEntry) => Number(entry.description.split(' ')[0]) % 1 == 0
		),
	cardEntries: (state): ProgressCardEntries => state.card.entries ?? [],
	cardEntryByName:
		(state) =>
		(title: string): ProgressCardEntries =>
			state.card.entries.filter((entry: ProgressCardEntry) => entry.description === title) ??
			[],
	cardLoaded: (state): boolean => state.card.loaded ?? false,
	cardsWithHomeWork: (state): number[] =>
		removeDuplicateEntries(
			state.card.entries
				.filter((entry: ProgressCardEntry) => entry.result === 'H')
				.map((entry: ProgressCardEntry): number =>
					parseInt(entry.description.split('.')[0])
				)
		) ?? [],
	cardEntriesWithHomeWork: (state): ProgressCardEntries =>
		state.card.entries.filter((entry: ProgressCardEntry) => entry.result === 'H') || [],
};

export { getters };
