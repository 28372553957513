// Vendor
import { ActionTree } from 'vuex';

// Types
import { RoutePayload } from '@/@types/store/router';
import { RootState } from '@/@types/store';

// Router
import { router } from '@/router';

export const actions: ActionTree<any, RootState> = {
	setLocation: (_, href: string): void => {
		window.location.href = href;
	},
	pushRoute: (_, payload: RoutePayload): any => {
		if (typeof payload.name !== 'string') throw new Error('route is not a string');

		const routeName = `${payload.name}`;

		const routeObj = {
			...payload,
			name: routeName,
			params: {
				...router.currentRoute.value.params,
				...payload.params,
			},
		};

		payload.direction === 'root' ? router.replace(routeObj) : router.push(routeObj);

		setTimeout(() => {
			if (typeof payload.chain?.name === 'string') {
				router.push({
					name: `${payload.chain.name}`,
					params: {
						...router.currentRoute.value.params,
						...payload.chain.params,
					},
				});
			}
		}, 200);
	},
};
