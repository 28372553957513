// Vendor
import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { Preferences } from '@capacitor/preferences';

// Types
import {
	FetchViewedDataOptions,
	SaveViewedDataOptions,
	WolfRouteState,
} from '@/@types/store/wolfroute';
import { RootState } from '@/@types/store';

// Constants
import { WOLF_ROUTE_FIRST_TASK_KEY } from '@/@constants/wolfroute';

// Plugins
import { API, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { store } from '@/store';
import { t } from '@/translations';

const actions: ActionTree<WolfRouteState, RootState> = {
	fetchViewedData: async (
		{ dispatch, rootGetters },
		options: FetchViewedDataOptions
	): Promise<void> => {
		const { id } = options.studentFromStore ? rootGetters['student/data'] : options.student;

		const url: string = setQueryParams(ENDPOINTS.VIEWED, 'usable_json', {
			user: id,
		});

		const request: AxiosResponse = await API.get(url, {
			apiKey: true,
			token: 'secure',
		}).catch((error: AxiosResponse) => {
			if (error.status === 404) {
				// const taskOptions: SaveViewedDataOptions = {
				// 	fetchViewedState: true,
				// 	studentFromStore: true,
				// 	type: 'task',
				// 	type_id: 'register',
				// };

				// dispatch('saveTaskToAccount', taskOptions);

				return Promise.resolve(error);
			}

			return Promise.reject(error);
		});

		if (request?.data && options.updateState) {
			const { data } = request.data;

			dispatch('setViewedData', data);
		}

		return request.data;
	},
	saveTaskToAccount: async (
		{ dispatch, rootGetters },
		options: SaveViewedDataOptions
	): Promise<AxiosResponse | boolean> => {
		const { id } = options.studentFromStore ? rootGetters['student/data'] : options.student;
		const { isFirstTask, type, type_id } = options;

		if (isFirstTask) {
			await Preferences.set({
				key: WOLF_ROUTE_FIRST_TASK_KEY,
				value: 'true',
			});

			await dispatch('setFirstTaskFromStorage');
		}

		if (id) {
			const url: string = setQueryParams(ENDPOINTS.VIEWED, 'usable_json');

			const request: AxiosResponse = await API.post(
				url,
				{
					type: isFirstTask ? 'task' : type,
					type_id: isFirstTask ? 'first_task' : type_id,
					user: id,
				},
				{
					apiKey: true,
					token: 'secure',
				}
			).catch((error: AxiosResponse) => {
				store.dispatch('userInterface/showToast', {
					color: 'danger',
					text: t('wolfroute.viewed.error.post'),
				});

				return Promise.reject(error);
			});

			if (request.data && options.fetchViewedState) {
				const fetchViewedOptions: FetchViewedDataOptions = {
					studentFromStore: true,
					updateState: true,
				};

				return dispatch('fetchViewedData', fetchViewedOptions);
			}

			return request?.data;
		}

		return false;
	},
	resetViewed: ({ commit }): void => {
		return commit('RESET_VIEWED_DATA');
	},
	setFirstTaskFromStorage: async ({ commit }): Promise<string | null> => {
		const firstTaskStorage = await Preferences.get({
			key: WOLF_ROUTE_FIRST_TASK_KEY,
		});

		commit('SET_FIRST_TASK_FROM_STORAGE', firstTaskStorage.value !== null);

		return firstTaskStorage.value;
	},
	setViewedData: ({ commit }, payload: any): void => {
		return commit('SET_VIEWED_DATA', payload);
	},
};

export { actions };
