// Vendor
import { GetterTree } from 'vuex';
import dayjs from 'dayjs';

// Types
import {
	Appointment,
	AppointmentListType,
	AppointmentPlannerError,
	Appointments,
	AppointmentState,
	AppointmentStatus,
	AppointmentType,
	CurrentAppointment,
	PutAppointmentResult,
	SingleAppointmentState,
} from '@/@types/store/appointment';
import { RootState } from '@/@types/store';

// Constants
import {
	PROTECTED_APPOINTMENT_TYPES,
	PROTECTED_APPOINTMENT_COLOR_CODES,
	REQUIRED_HOUR_DIFF,
	PLANNER_SETTINGS,
} from '@/@constants/appointment';

const { VUE_APP_LESSON_LENGTH } = process.env;

const getters: GetterTree<AppointmentState, RootState> = {
	appointmentsByAppointmentState:
		(state) =>
		(
			singleAppointmentState: SingleAppointmentState,
			listType: AppointmentListType
		): Appointments => {
			if (singleAppointmentState === 'passed') {
				// Passed appointments are sorted from latest first, thus reverse chronologic order
				return (
					state?.[listType]?.list
						?.filter((appointment: Appointment) =>
							dayjs(`${appointment.date} ${appointment.timeEnd}`).isBefore(dayjs())
						)
						?.reverse() || []
				);
			}

			return (
				state?.[listType]?.list?.filter((appointment: Appointment) =>
					dayjs(`${appointment.date} ${appointment.timeEnd}`).isAfter(dayjs())
				) || []
			);
		},
	appointmentById:
		(state) =>
		(id: number, listType: AppointmentListType): Appointment | 'not_found' =>
			state?.[listType]?.list?.filter((appointment) => appointment.id === id)[0] ||
			'not_found',
	current: (state): CurrentAppointment => state.current,
	isAppointmentProcessing: (state) => (id: string) => state.processing.includes(id),
	doubleAppointmentNotation:
		() =>
		(appointmentAmount: number, round?: boolean): number =>
			round ? Math.floor(appointmentAmount / 2) : appointmentAmount / 2,
	feedback:
		(state) =>
		(listType: AppointmentListType): PutAppointmentResult =>
			state?.[listType]?.feedback || {
				errorResponses: [],
				successResponses: [],
			},
	hasAppointments:
		(state) =>
		(listType: AppointmentListType): boolean =>
			state?.[listType]?.list.length > 0 || false,
	hasAppointmentType:
		(state) =>
		(
			type: Array<AppointmentType> | AppointmentType,
			listType: AppointmentListType
		): boolean => {
			const processedTypes = typeof type === 'string' ? [type] : type;

			return (
				state?.[listType]?.list?.some(
					(appointment: Appointment) => processedTypes.indexOf(appointment.type) !== -1
				) || false
			);
		},
	appointmentTypeAmount:
		(state) =>
		(type: Array<AppointmentType> | AppointmentType, listType: AppointmentListType): number => {
			const processedTypes = typeof type === 'string' ? [type] : type;

			return (
				state?.[listType]?.list?.filter(
					(appointment: Appointment) => processedTypes.indexOf(appointment.type) !== -1
				).length || 0
			);
		},
	hourDiff:
		() =>
		(appointment: Appointment): number =>
			dayjs(`${appointment?.date} ${appointment?.timeEnd}`).diff(dayjs(), 'hours'),
	isInFuture:
		() =>
		(appointment: Appointment): boolean =>
			dayjs(`${appointment?.date} ${appointment?.timeEnd}`).isAfter(dayjs()),
	isInPast:
		() =>
		(appointment: Appointment): boolean =>
			dayjs(`${appointment?.date} ${appointment?.timeEnd}`).isBefore(dayjs()),
	latestPlannedAppointment: (_, getters): Appointment | undefined => {
		const appointments = getters.appointmentsByAppointmentState('planned', 'agenda');

		return appointments.length > 0 ? appointments[0] : undefined;
	},
	list:
		(state) =>
		(listType: AppointmentListType): Appointments =>
			state?.[listType]?.list || [],
	listDuration:
		(state) =>
		(listType: AppointmentListType, type?: AppointmentType): number => {
			const list = type
				? state?.[listType]?.list.filter((appointment) => appointment.type === type)
				: state?.[listType]?.list;

			return (
				list.reduce(
					(prevVal: number, appointment: Appointment): number =>
						prevVal +
						dayjs(`${appointment.date} ${appointment.timeEnd}`).diff(
							dayjs(`${appointment.date} ${appointment.timeStart}`),
							'minute'
						),
					0
				) || 0
			);
		},
	loaded:
		(state) =>
		(listType: AppointmentListType): boolean =>
			state?.[listType]?.loaded || false,
	minutesToLessons:
		() =>
		(minutes: number, round?: boolean): number =>
			round ? Math.floor(minutes / VUE_APP_LESSON_LENGTH) : minutes / VUE_APP_LESSON_LENGTH,
	planBalanceError:
		() =>
		(
			userMayBuy: boolean,
			plannableLessons: number,
			disabled: boolean
		): boolean | AppointmentPlannerError => {
			if (disabled) {
				return 'balance_minimum_negative_disabled';
			}

			if (userMayBuy && plannableLessons <= 0) {
				return 'balance_minimum_negative_plannable';
			}

			if (userMayBuy && plannableLessons <= PLANNER_SETTINGS.BALANCE_NOTICE_TRESHOLD) {
				return 'balance_minimum';
			}

			if (!userMayBuy && plannableLessons <= PLANNER_SETTINGS.BALANCE_NOTICE_TRESHOLD) {
				return 'balance_minimum_buy_disabled';
			}

			return false;
		},
	status:
		(_, getters) =>
		(appointment: Appointment): AppointmentStatus => ({
			protected:
				appointment?.type !== 'lesuur' ||
				(getters.isInFuture(appointment) &&
					getters.hourDiff(appointment) < REQUIRED_HOUR_DIFF) ||
				PROTECTED_APPOINTMENT_TYPES.indexOf(appointment?.type) !== -1 ||
				PROTECTED_APPOINTMENT_COLOR_CODES.indexOf(appointment?.colorCode) !== -1,
			reason:
				PROTECTED_APPOINTMENT_TYPES.indexOf(appointment?.type) !== -1
					? 'appointment_type'
					: PROTECTED_APPOINTMENT_COLOR_CODES.indexOf(appointment?.colorCode) !== -1
					? 'appointment_color_code'
					: getters.hourDiff(appointment) < REQUIRED_HOUR_DIFF
					? 'hours'
					: 'general',
		}),
};

export { getters };
