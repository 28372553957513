// Vendor
import { Module } from 'vuex';

// Types
import { StudentState } from '@/@types/store/student';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): StudentState => ({
	canPlan: null,
	data: {
		advice: {},
		email: '',
		firstName: '',
		hasGlasses: false,
		id: 0,
		language: '',
		lastName: '',
	},
	loaded: false,
});

const student: Module<StudentState, RootState> = {
	namespaced: true,
	state: defaultState(),
	actions,
	getters,
	mutations,
};

export { student };
