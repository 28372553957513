// Vendor
import dayjs from 'dayjs';

// Constants
import { EXTENDED_DATE_FORMAT } from '@/@constants/date';
import { APP_LOCALE } from '@/@constants/locale';

// Plugins
import { i18n } from '@/translations';

class UserInterfaceBundle {
	constructor(private n = i18n.global.n, private locale = i18n.global.locale.value) {}

	stripChapterNumbers = (description: string): string => {
		const task = /(\d\.\d*\s*)(.*)/.exec(description);

		return task instanceof Array ? task[2] : description;
	};

	displayPrice(price?: number, format = 'currency', raw = false): number | string {
		return raw ? price || 0 : this.n(price || 0, format, this.numberLocale(this.locale));
	}

	displayDate(date: Date | string, format = EXTENDED_DATE_FORMAT): string {
		return dayjs(new Date(date)).format(format);
	}

	numberLocale(locale: string): APP_LOCALE {
		return locale.replace('_', '-') as APP_LOCALE;
	}
}

export { UserInterfaceBundle };
