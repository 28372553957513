// Vendor
import { RouteRecordRaw } from 'vue-router';

// Constants
import { ROLE } from '@/@constants/auth';

// Plugins
import { t } from '@/translations';

// Routes
import { appointmentRoutes } from './appointment';
import { authRoutes } from './auth';
import { taskRoutes } from './tasks';
import { wolfRoutes } from './wolf';

import TabsView from '@/views/Tabs.vue';
import TrialWrapper from '@/views/Trial/StepWrapper.vue';

export const routes: RouteRecordRaw[] = [
	{
		path: '/',
		redirect: `/:locale/t/${t('routes.route.slug')}`,
	},
	{
		name: 'page.success',
		path: `/:locale/${t('routes.success.slug')}`,
		component: () => import('@/views/Feedback/Success.vue'),
	},
	{
		name: 'order.processing',
		path: `/:locale/${t('routes.order_processing.slug')}`,
		component: () => import('@/views/Order/Appointment/Processing.vue'),
	},
	{
		name: 'order.share',
		path: `/:locale/${t('routes.order_share.slug')}/:hash`,
		component: () => import('@/views/Order/SharedOrder.vue'),
	},
	{
		name: 'invoice.share',
		path: `/:locale/${t('routes.invoice_share.slug')}/:studentId/:hash`,
		component: () => import('@/views/Order/SharedOrder.vue'),
	},
	{
		name: 'page.download',
		path: `/:locale/${t('routes.download.slug')}`,
		component: () => import('@/views/DownloadRedirect.vue'),
	},
	{
		name: 'trial',
		path: `/:locale/${t('routes.trial.slug')}`,
		component: TrialWrapper,
		children: [
			{
				path: '',
				redirect: `/:locale/${t('routes.trial.slug')}1`,
			},
			{
				name: 'trial.1',
				path: '1',
				meta: {
					title: t('trial.content.step.1.meta_title'),
				},
				component: () => import('@/views/Trial/Steps/1.vue'),
			},
			{
				name: 'trial.2',
				path: '2',
				meta: {
					title: t('trial.content.step.2.meta_title'),
				},
				component: () => import('@/views/Trial/Steps/2.vue'),
			},
			{
				name: 'trial.3',
				path: '3',
				meta: {
					title: t('trial.content.step.3.meta_title'),
				},
				component: () => import('@/views/Trial/Steps/3.vue'),
			},
			{
				name: 'trial.4',
				path: '4',
				meta: {
					title: t('trial.content.step.4.meta_title'),
				},
				component: () => import('@/views/Trial/Steps/4.vue'),
			},
		],
	},
	{
		name: 'tabs.wrapper',
		path: '/:locale/t/',
		component: TabsView,
		children: [
			{
				path: '',
				redirect: t('routes.route.slug'),
			},
			{
				meta: {
					title: 'routes.more.title',
				},
				name: 'tabs.more',
				path: t('routes.more.slug'),
				component: () => import('@/views/More.vue'),
			},
			{
				meta: {
					minRequiredRole: ROLE.STUDENT,
					backButtonText: 'routes.more.title_compact',
					title: 'routes.invoice_overview.title',
				},
				name: 'tabs.more.invoice_overview',
				path: `${t('routes.more.slug')}/${t('routes.invoice_overview.slug')}`,
				component: () => import('@/views/Invoice/Overview.vue'),
			},
			{
				meta: {
					minRequiredRole: ROLE.STUDENT,
					backButtonText: 'routes.more.title_compact',
					title: 'routes.order_balance.title',
				},
				name: 'tabs.more.order_balance',
				path: `${t('routes.more.slug')}/${t('routes.order_balance.slug')}`,
				component: () => import('@/views/Order/Appointment/Balance.vue'),
			},
			{
				meta: {
					minRequiredRole: ROLE.STUDENT,
					backButtonText: 'routes.more.title_compact',
					title: 'routes.student_detail.title',
				},
				name: 'tabs.more.student_detail',
				path: `${t('routes.more.slug')}/${t('routes.student_detail.slug')}`,
				component: () => import('@/views/Student/Detail.vue'),
			},
			{
				meta: {
					minRequiredRole: ROLE.STUDENT,
					backButtonText: 'routes.more.title_compact',
					title: 'routes.my_plan.title',
				},
				name: 'tabs.more.my_plan',
				path: `${t('routes.more.slug')}/${t('routes.my_plan.slug')}`,
				component: () => import('@/views/Order/DrivingPlan.vue'),
			},
			{
				meta: {
					minRequiredRole: ROLE.STUDENT,
					backButtonText: 'routes.my_plan.title',
					title: 'routes.packages.title',
				},
				name: 'tabs.more.packages',
				path: `${t('routes.more.slug')}/${t('routes.packages.slug')}`,
				component: () => import('@/views/Order/Appointment/Packages.vue'),
			},
			{
				meta: {
					minRequiredRole: ROLE.STUDENT,
					backButtonText: 'routes.my_plan.title',
					title: 'routes.order_balance.title',
				},
				name: 'tabs.more.order_balance',
				path: `${t('routes.more.slug')}/${t('routes.order_balance.slug')}`,
				component: () => import('@/views/Order/Appointment/Balance.vue'),
			},
			{
				meta: {
					backButtonText: 'routes.more.title_compact',
				},
				name: 'tabs.more.content_page',
				path: `${t('routes.more.slug')}/p/:slug`,
				component: () => import('@/views/Content/Single.vue'),
			},
			...authRoutes,
			...appointmentRoutes,
			...taskRoutes,
			...wolfRoutes,
		],
	},
];
