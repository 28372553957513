// Vendor
import { MutationTree } from 'vuex';

// Types
import { NotificationState } from '@/@types/store/notification';

const mutations: MutationTree<NotificationState> = {
    SET_PERMISSION (state: NotificationState, payload: PermissionState): void {
        state.permission = payload;
    }
};

export { mutations };
