// Vendor
import { GetterTree } from 'vuex';

// Types
import { Instructor, InstructorState } from '@/@types/store/instructor';
import { RootState } from '@/@types/store';

const getters: GetterTree<InstructorState, RootState> = {
    data: (state): Instructor => state.data,
    displayName: (state): string =>
        `${state.data.firstName} ${state.data.insertion ? `${state.data.insertion} ` : ''}${
            state.data.lastName
        }`,
    displayNameByInstructor: () => (instructor: Instructor): string =>
        `${instructor?.firstName} ${instructor?.insertion ? `${instructor.insertion} ` : ''}${
            instructor?.lastName
        }`,
    loaded: (state: InstructorState): boolean => state.loaded || false,
};

export { getters };
