// Vendor
import { RouteLocationNormalized } from 'vue-router';

// Types
import { RouteTitlePosition } from '@/@types/router';

// Plugins
import { t } from '@/translations';

class NavigationHook {
	private appTitle = t('general.title');

	constructor(private to: RouteLocationNormalized, private from: RouteLocationNormalized) {}

	setDocumentTitle(location: RouteTitlePosition = 'before'): void {
		const title = this.to.meta.title ? t(`${this.to.meta.title}`) : '';

		document.title = `${this.to.meta.title && location === 'before' ? `${title} - ` : ''}${
			this.appTitle
		}${this.to.meta.title && location === 'after' ? ` - ${title}` : ''}`;
	}
}

export { NavigationHook };
