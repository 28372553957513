// Vendor
import { Module } from 'vuex';

// Types
import { OrderState } from '@/@types/store/order';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): OrderState => ({
    current: undefined,
    processing: false
});

const order: Module<OrderState, RootState> = {
    namespaced: true,
    state: defaultState(),
    actions,
    getters,
    mutations,
};

export { order };
