// Vendor
import { GetterTree } from 'vuex';

// Types
import { NetworkState } from '@/@types/store/network';
import { RootState } from '@/@types/store';

const getters: GetterTree<NetworkState, RootState> = {
    isConnected: (state): boolean => state.connected,
};

export { getters };
