// Vendor
import { ActionTree } from 'vuex';
import { Network, ConnectionStatus } from '@capacitor/network';

// Types
import { FetchNetworkStateOptions, NetworkState } from '@/@types/store/network';
import { RootState } from '@/@types/store';

const actions: ActionTree<NetworkState, RootState> = {
    fetchNetworkStatus: async (
        { dispatch },
        options: FetchNetworkStateOptions
    ): Promise<ConnectionStatus> => {
        const networkStatus = await Network.getStatus();

        if (options.updateState === true) {
            dispatch('setNetworkStatus', networkStatus);
        }

        return networkStatus;
    },
    setNetworkStatus: ({ commit }, payload: ConnectionStatus): void => {
        return commit('SET_NETWORK_STATUS', payload);
    },
};

export { actions };
