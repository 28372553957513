const ACCESS_TOKEN_KEY = 'access_token';
const APP_ACCESS_KEY = 'app_access_token';
const REGISTRATION_KEY = 'registration';
const REGISTRATION_STEPS_AMOUNT = 3;
const REFRESH_TOKEN_KEY = 'refresh_token';
const USER_KEY = 'user';

enum ROLE {
	ANONYMOUS = 0,
	STUDENT = 1,
}

export {
	ACCESS_TOKEN_KEY,
	APP_ACCESS_KEY,
	REFRESH_TOKEN_KEY,
	REGISTRATION_KEY,
	REGISTRATION_STEPS_AMOUNT,
	ROLE,
	USER_KEY,
};
