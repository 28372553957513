// Vendor
import { MutationTree } from 'vuex';

// Types
import { MediaState, PlaylistItems } from '@/@types/store/media';

const mutations: MutationTree<MediaState> = {
    SET_PLAYLIST_ITEMS (state: MediaState, items: PlaylistItems): void {
        state.playlist.items = items;
    },
    SET_PLAYLIST_LOADED (state: MediaState, loaded: boolean): void {
        state.playlist.loaded = loaded;
    },
    SET_VIDEO (state: MediaState, payload: string): void {
        state.video = payload;
    }
};

export { mutations };
