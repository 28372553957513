// Vendor
import { ActionTree } from 'vuex';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Share } from '@capacitor/share';
import { useGTM } from '@netvlies/utility-collection';
import { App, AppLaunchUrl } from '@capacitor/app';
import { isPlatform, modalController } from '@ionic/vue';
import * as Sentry from '@sentry/vue';

// Types
import {
	FetchOrderOptions,
	Order,
	OrderResponse,
	OrderState,
	PostOrderOptions,
} from '@/@types/store/order';
import { RootState } from '@/@types/store';
import { FlexpulseResponse } from '@/@types/api';
import { Student } from '@/@types/store/student';

// Plugins
import { API, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { t, locale } from '@/translations';
import { GTM_AFFILIATION } from '@/@constants/app';

const { VUE_APP_API_ORDER_REDIRECT_URL, VUE_APP_WEB_URL } = process.env;
const { push: pushEvent } = useGTM();

const actions: ActionTree<OrderState, RootState> = {
	postOrder: async (
		{ dispatch, getters, rootGetters },
		options: PostOrderOptions
	): Promise<unknown | boolean> => {
		dispatch('setOrderProcessing', true);

		const student: Student = rootGetters['student/data'];

		const {
			amount = 1,
			productId,
			redirect = VUE_APP_API_ORDER_REDIRECT_URL,
			sharePayment = false,
		} = options || {};

		if (amount && productId) {
			await dispatch('setOrder', [
				{
					amount,
					productId,
				},
			]);
		}

		const url: string = setQueryParams(ENDPOINTS.ORDER, 'json');

		const redirectUrl =
			isPlatform('android') && !isPlatform('mobileweb')
				? `wolfapp://localhost/nl_NL/${redirect}`
				: `${window.location.origin.replace('capacitor', 'wolfapp')}${redirect}`;

		const request: AxiosResponse<FlexpulseResponse<OrderResponse>> = await API.post(
			url,
			{
				order: getters.current,
				placeOrder: 1,
				redirectUrl,
			},
			{
				apiKey: true,
				token: 'secure',
			}
		)
			.catch((error: AxiosResponse<FlexpulseResponse>) => {
				dispatch(
					'userInterface/showToast',
					{
						color: 'danger',
						text: t(`error.code.${error.data.error.errorCode}`),
					},
					{
						root: true,
					}
				);

				return Promise.reject(error);
			})
			.finally(() => {
				dispatch('setOrder', undefined);
			});

		if (sharePayment) {
			pushEvent('share_payment', {
				user_id: student.id,
			});

			Sentry.captureMessage('Share payment', 'info');

			if (rootGetters['app/canShare'] && request.data.result.hash) {
				dispatch('setOrderProcessing', false);

				const shareUrl = `${VUE_APP_WEB_URL}/${locale.value}/${t(
					'routes.order_share.slug'
				)}/${request.data.result.hash}`;

				// Share on mobile devices
				await Share.share({
					title: t('order.share.title'),
					text: t('order.share.text'),
					url: shareUrl,
					dialogTitle: t('order.share.title'),
				});
			} else if (request.data.result.sharePaymentUrl) {
				// Create mailto link on desktop
				const shareUrl = `${VUE_APP_WEB_URL}/${locale.value}/${t(
					'routes.order_share.slug'
				)}/${request.data.result.hash}`;
				const mailbody = `${t('order.share.text')}\n\n${shareUrl}`;
				window.open(
					`mailto:?to=&body=${encodeURIComponent(mailbody)}&subject=${t(
						'order.share.title'
					)}`,
					'_blank'
				);
			} else {
				dispatch(
					'userInterface/showToast',
					{
						color: 'danger',
						text: t('order.share.error'),
					},
					{ root: true }
				);
			}
		} else if (request.data.result.paymentUrl) {
			if (request.data.result.orderData?.length) {
				const eventData = {
					user_id: student.id,
					currency: 'EUR',
					value: request.data.result.amount,
					items: request.data.result.orderData.map((product) => {
						return {
							affiliation: GTM_AFFILIATION,
							currency: 'EUR',
							item_id: product.productId,
							item_name: product.productId,
							quantity: product.amount,
						};
					}),
				};
				pushEvent('begin_checkout', eventData);
				Sentry.setContext('order', eventData);
			} else {
				pushEvent('begin_checkout', {
					user_id: student.id,
					currency: 'EUR',
					value: request.data.result.totalPriceIncVat,
				});

				Sentry.setContext('order', {
					user_id: student.id,
					currency: 'EUR',
					value: request.data.result.totalPriceIncVat,
					transaction_id: request.data.result.transactionId,
					hash: request.data.result.hash,
					order: getters.current,
					redirect: redirectUrl,
				});
			}
			Sentry.captureMessage('Begin checkout', 'info');

			window.location = request.data.result.paymentUrl as string & Location;

			App.addListener('appUrlOpen', async (data: AppLaunchUrl) => {
				const { url } = data;
				const transactionId = url.split('transactionId=')[1];

				if (transactionId && url.includes('wolfapp://') && url.includes('proefles')) {
					const modal = await modalController.getTop();

					if (modal !== null) {
						modalController.dismiss();
					}

					dispatch(
						'router/pushRoute',
						{
							name: 'trial.4',
							query: {
								transactionId,
							},
						},
						{
							root: true,
						}
					);

					App.removeAllListeners();
				} else if (transactionId && url.includes('wolfapp://')) {
					const modal = await modalController.getTop();

					if (modal !== null) {
						modalController.dismiss();
					}

					dispatch(
						'router/pushRoute',
						{
							direction: 'root',
							name: 'order.processing',
							query: {
								transactionId,
							},
						},
						{
							root: true,
						}
					);

					App.removeAllListeners();
				}
			});
		}

		dispatch('setOrderProcessing', false);

		return request?.data?.result ?? false;
	},
	fetchOrder: async (_, options: FetchOrderOptions): Promise<unknown | boolean> => {
		const { transactionId } = options || {};

		const url = setQueryParams(ENDPOINTS.ORDER, 'json', {
			...options,
		});

		const config: AxiosRequestConfig = {
			apiKey: true,
			token: transactionId ? 'secure' : 'secure_app',
		};

		const request: AxiosResponse<FlexpulseResponse<OrderResponse>> = await API.get(
			url,
			config
		).catch((error: AxiosResponse<FlexpulseResponse>) => {
			return Promise.reject(error);
		});

		return request?.data?.result ?? false;
	},
	addToOrder: async ({ commit, getters }, payload: Order): Promise<void> => {
		return commit('SET_CURRENT_ORDER', getters.current.push(payload));
	},
	setOrder: ({ commit }, payload: Order[] | undefined): void => {
		return commit('SET_CURRENT_ORDER', payload);
	},
	setOrderProcessing: ({ commit }, payload: boolean): void => {
		return commit('SET_PROCESSING', payload);
	},
};

export { actions };
