// Vendor
import { Module } from 'vuex';

// Types
import { MediaState } from '@/@types/store/media';
import { RootState } from '@/@types/store';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export const defaultState = (): MediaState => ({
    playlist: {
        items: [],
        loaded: false
    },
    video: null
});

const media: Module<MediaState, RootState> = {
    namespaced: true,
    state: defaultState(),
    actions,
    getters,
    mutations,
};

export { media };
