// Vendor
import { MutationTree } from 'vuex';
import { ConnectionStatus } from '@capacitor/network';

// Types
import { NetworkState } from '@/@types/store/network';

const mutations: MutationTree<NetworkState> = {
    SET_NETWORK_STATUS(state: NetworkState, payload: ConnectionStatus) {
        Object.assign(state, payload);
    },
};

export { mutations };
