// Vendor
import { GetterTree } from 'vuex';

// Types
import { ContentEntry, ContentState } from '@/@types/store/content';
import { RootState } from '@/@types/store';

const getters: GetterTree<ContentState, RootState> = {
    data: (state): ContentEntry => state.data,
    loaded: (state): boolean => state.loaded || false,
};

export { getters };
