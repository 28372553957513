// Types
import { Student } from "@/@types/store/student";

// Constants
import { WOLF_ROUTE_CUSTOM_EXAM_CITIES } from "@/@constants/wolfroute";

export const cityToTranslationKey = (city: string): string => city.replace(' ', '_').toLowerCase();

export const mapExamCity = (student: Student): string => {
    const { cf1 } = student;

    /**
     * Force lowercase on city field to do correct mapping for check
     */
    if (cf1 && WOLF_ROUTE_CUSTOM_EXAM_CITIES.indexOf(cityToTranslationKey(cf1.toLowerCase())) !== -1) {
        return cityToTranslationKey(cf1);
    }

    switch (cf1) {
        case 'nijmegen':
            return 'den_bosch';
        case 'leiden':
            return 'den_haag';
        case 'mierlo':
            return 'eindhoven';
        case 'barendrecht':
            return 'rotterdam';
        case 'goes':
        case 'middelburg':
            return 'vlissingen';
        case 'haarlem':
        case 'zwolle':
            return 'amsterdam';
        default:
            return 'general';
    }
}
