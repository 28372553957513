// Vendor
import { GetterTree } from 'vuex';

// Types
import { OrderState } from '@/@types/store/order';
import { RootState } from '@/@types/store';

const getters: GetterTree<OrderState, RootState> = {
    current: (state) => state.current,
    processing: (state) => state.processing,
};

export { getters };
