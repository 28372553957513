// Vendor
import { ActionTree } from 'vuex';
import { actionSheetController, modalController, toastController } from '@ionic/vue';
import { OverlayEventDetail } from '@ionic/core';

// Types
import {
    ActionSheetOptions,
    ShowModalOptions,
    ToastButtons,
    ToastOptions,
    UserInterfaceState,
} from '@/@types/store/userInterface';
import { RootState } from '@/@types/store';

// Plugins
import { i18n } from '@/translations';

const { t } = i18n.global;

export const actions: ActionTree<UserInterfaceState, RootState> = {
    showActionSheet: async (_, options: ActionSheetOptions): Promise<void> => {
        const { buttons, title } = options;

        if (buttons && options.cancelButton) {
            buttons.push({
                role: 'cancel',
                text: t('general.cancel'),
            });
        }

        const actionSheet = await actionSheetController.create({
            buttons: options.buttons,
            header: title,
        });

        return actionSheet.present();
    },
    showModal: async (_, options: ShowModalOptions): Promise<void> => {
        const modal = await modalController.create({
            presentingElement: options.presentingElement,
            canDismiss: true,
            component: options.component,
            componentProps: {
                ...options.componentProps,
                isModal: true,
            }
        });

        modal.onDidDismiss().then(async (result: OverlayEventDetail) => {
            const parentModal = await modalController.getTop();

            if (parentModal && (result?.data?.closeParent || options.closeParentOnDismiss === true)) {
                modalController.dismiss();
            }
        });

        return modal.present().then(() => {
            /**
             * Current version of @ionic/vue (5.5.5) has issue of adding modal content twice.
             * Remove duplicate content here to prevent DOM pollution
             * TODO: report issue to Ionic team
             */
            const duplicateModalContent: HTMLElement | null = modal.querySelector('.ion-page > .ion-page:nth-child(2)');

            if (duplicateModalContent instanceof HTMLElement) {
                duplicateModalContent.remove();
            }
        });
    },
    showToast: async (_, options: ToastOptions): Promise<void> => {
        const buttons: ToastButtons = options.buttons ?? [];

        if (options.dismissable === true) {
            buttons.push({
                role: 'cancel',
                text: t('general.dismiss'),
            });
        }

        const toast = await toastController.create({
            color: options.color ?? 'primary',
            duration: options.duration ?? 3000,
            header: options.title,
            message: options.text,
            buttons,
        });

        toast.present();
    },
    setDarkMode: ({ commit }, payload: boolean): void => {
        return commit('SET_DARK_MODE', payload);
    }
};
