import { App } from 'vue';

import Loader from '@/components/atoms/Loader.vue';
import Heading from '@/components/atoms/Heading.vue';
import UserContent from '@/components/atoms/User/Content.vue';

import FaqEntity from '@/components/entity/type/faq_items.vue';
import TextEntity from '@/components/entity/type/text.vue';

export const registerComponents = (app: App): void => {
	app.component('base-loader', Loader);
	app.component(Heading.name, Heading);
	app.component(UserContent.name, UserContent);

	app.component('faq_itemsEntity', FaqEntity);
	app.component('textEntity', TextEntity);
};
