// Types
import { ContactOptions } from '@/@types/contact';
import { Instructor } from '@/@types/store/instructor';

// Helpers
import { createChat } from '@/helpers/chat';

// Plugins
import { i18n } from '@/translations';

const { t } = i18n.global;

export const BASE_CONTACT_OPTIONS = (instructor?: Instructor, general = false): ContactOptions => [
	{
		text: t('contact.method.call.title_long'),
		link: 'tel:0881000800',
		handler: () => window.open('tel:0881000800', '_blank'),
	},
	{
		text: t('contact.method.mail.title_long'),
		link: 'mailto:klantenservice@wolf.nl',
		handler: () => window.open('mailto:klantenservice@wolf.nl', '_blank'),
	},
	{
		text: t('contact.method.chat.title_long'),
		handler: () => createChat(instructor, general),
	},
];

export const DEFAULT_PHONE_NR = '31681615849';
