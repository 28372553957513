// Vendor
import { createStore } from 'vuex';
import { Preferences } from '@capacitor/preferences';
import createPersistedState from 'vuex-persistedstate';
import merge from 'deepmerge';

// Modules
import { app } from './modules/app';
import { appointment } from './modules/appointment';
import { auth } from './modules/auth';
import { content } from './modules/content';
import { instructor } from './modules/instructor';
import { media } from './modules/media';
import { network } from './modules/network';
import { notification } from './modules/notification';
import { order } from './modules/order';
import { progress } from './modules/progress';
import { product } from './modules/product';
import { router } from './modules/router';
import { student } from './modules/student';
import { task } from './modules/task';
import { userInterface } from './modules/userInterface';
import { wolfroute } from './modules/wolfroute';

const debug = process.env.NODE_ENV !== 'production';

const persistedState = createPersistedState({
	key: 'wolfappstate',
	paths: ['app.version', 'auth.loggedIn', 'auth.registrationData', 'student.data'],
	storage: {
		getItem: async (key) => {
			const result = await Preferences.get({
				key,
			});

			// TODO: mv logic to npm module to support async storage actions
			store.replaceState(
				merge(store.state, JSON.parse(result.value || '{}'), {
					arrayMerge: function (_, saved) {
						return saved;
					},
					clone: false,
				})
			);

			return result.value;
		},
		setItem: async (key, value) => {
			const response = await Preferences.set({
				key,
				value,
			});

			return response;
		},
		removeItem: async (key) => {
			const response = await Preferences.remove({
				key,
			});

			return response;
		},
	},
	fetchBeforeUse: false,
});

const store = createStore({
	modules: {
		app,
		appointment,
		auth,
		content,
		instructor,
		media,
		network,
		notification,
		order,
		progress,
		product,
		router,
		student,
		task,
		userInterface,
		wolfroute,
	},
	devtools: debug,
	plugins: [persistedState],
});

export { store };
