// Vendor
import { defineRule } from 'vee-validate';
import dayjs from 'dayjs';

// plugins
import { i18n } from '@/translations';

// Constants
import { DEFAULT_DATE_FORMAT } from '@/@constants/date';

const { t } = i18n.global;

export const setupCoreFormRules = () => {
	defineRule('required', (value: string | number) => {
		const val = typeof value === 'string' ? value : JSON.stringify(value);

		if (!val?.length) {
			return t('form.field.required.error');
		}

		return true;
	});

	defineRule('email', (value: string) => {
		// Field is empty or matches regex should pass
		if (!value?.length || /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/g.test(value)) {
			return true;
		}

		return t('form.field.email.error');
	});

	// TODO: better regex for phone
	defineRule('phone', (value: string) => {
		// Field is empty or matches regex should pass
		if (
			!value?.length ||
			/\+?1?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/.test(value)
		) {
			return true;
		}

		return t('form.field.phone.error');
	});

	defineRule('postcode', (value: string) => {
		// Field is empty or matches regex should pass
		if (!value?.length || /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(value)) {
			return true;
		}

		return t('form.field.postcode.error');
	});

	defineRule('checked', (value: boolean | undefined) => {
		if (!value) {
			return t('form.field.checkbox.checked.error');
		}

		return true;
	});

	defineRule(
		'dateFuture',
		(
			date: string,
			[format = DEFAULT_DATE_FORMAT, label, includeToday = true]: [string, string, boolean]
		) => {
			if (
				date &&
				dayjs(date, format)
					.add(includeToday ? 1 : 0, 'days')
					.isBefore(dayjs())
			) {
				if (label) {
					return t('form.field.date_future.error_names', {
						label,
					});
				}

				return t(
					includeToday
						? 'form.field.date_future_today.error'
						: 'form.field.date_future.error'
				);
			}

			return true;
		}
	);

	defineRule('driveAge', (formDate: string, [plannedDate = dayjs()]) => {
		const date = dayjs(formDate, DEFAULT_DATE_FORMAT);
		if (date && dayjs(plannedDate).diff(date, 'year', true) < 16.5) {
			return t('form.field.drive_age.error');
		}

		return true;
	});

	defineRule('confirmed', (value: any, [other, type = 'email']: any) => {
		if (value !== other) {
			return t(`form.field.confirmed.${type}.error`);
		}

		return true;
	});
};
