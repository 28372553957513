// Vendor
import { GetterTree } from 'vuex';

// Types
import { AppState, CoreAction, RemoteAppData } from '@/@types/store/app';
import { RootState } from '@/@types/store';

const getters: GetterTree<AppState, RootState> = {
	coreActions: (state): CoreAction[] => state.coreActions,
	isLoaded: (state): boolean => state.loaded,
	canShare: (state): boolean => state.canShare,
	localVersion: (state): string | undefined =>
		(window.Cypress && window.localStorage.getItem('overrideAppVersion')) || state.version,
	outdated: (_, getters): boolean => {
		const {
			processedLocalAppVersion,
			remoteApp,
		}: { processedLocalAppVersion: number; remoteApp: RemoteAppData | null } = getters;

		if (processedLocalAppVersion === null || !remoteApp) {
			return false;
		}

		return processedLocalAppVersion < parseInt(remoteApp.app_version);
	},
	processedLocalAppVersion: (_, getters): number | null => {
		const { localVersion }: { localVersion: string } = getters;

		if (localVersion === 'web' || !localVersion) {
			return null;
		}

		return parseInt(
			localVersion
				.split('.')
				.map((tag) => `${parseInt(tag) < 10 ? '0' : ''}${tag}`)
				.join('.')
				.replace(/\./g, '')
		);
	},
	remoteApp: (state): RemoteAppData | null => state.remote,
};

export { getters };
