// Vendor
import { Module } from 'vuex';

// Types
import { AppState } from '@/@types/store/app';

// Constants
import { CORE_ACTIONS } from '@/@constants/app';

// Module specific imports
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { RootState } from '@/@types/store';

export const defaultState = (): AppState => ({
	coreActions: CORE_ACTIONS,
	loaded: false,
	remote: null,
	canShare: true,
});

const app: Module<AppState, RootState> = {
	namespaced: true,
	state: defaultState(),
	actions,
	getters,
	mutations,
};

export { app };
