// Vendor
import { MutationTree } from 'vuex';

// Types
import { Student, StudentState } from '@/@types/store/student';

import { defaultState } from './index';

const mutations: MutationTree<StudentState> = {
	RESET_STUDENT(state: StudentState): void {
		Object.assign(state, {
			...defaultState(),
		});
	},
	SET_CAN_PLAN(state, canPlan: boolean | null): void {
		state.canPlan = canPlan;
	},
	SET_STUDENT_DATA(state: StudentState, payload: Student): void {
		state.data = payload;
	},
	SET_STUDENT_LOADED(state: StudentState, payload: boolean): void {
		state.loaded = payload;
	},
};

export { mutations };
