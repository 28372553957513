// Vendor
import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { LocalNotificationSchema } from '@capacitor/local-notifications';
import { actionSheetController } from '@ionic/vue';
import { useGTM } from '@netvlies/utility-collection';
import dayjs from 'dayjs';

// Types
import {
	Appointment,
	AppointmentBaseOptions,
	AppointmentNotification,
	AppointmentResponse,
	AppointmentState,
	CurrentAppointment,
	FetchAppointmentByIdOptions,
	FetchAppointmentOptions,
	PutAppointmentListOptions,
	PutAppointmentOptions,
	PutAppointmentPayload,
	PutAppointmentResponse,
	PutAppointmentResult,
	PutAppointmentResultPayload,
	ScheduleAppointmentNotificationOptions,
	SetAppointmentListPayload,
	SetAppointmentLoadedPayload,
} from '@/@types/store/appointment';
import { CustomLocalNotificationInterface } from '@/@types/store/notification';
import { FetchStudentOptions, Student } from '@/@types/store/student';
import { RootState } from '@/@types/store';

// Constants
import { DEFAULT_NOTIFICATION_OFFSET_HOURS } from '@/@constants/appointment';
import { REJECTED_AUTH_STATUS_CODES } from '@/@constants/api';

// Plugins
import { API, setQueryParams } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { i18n } from '@/translations';

// Helpers
import iterateSerialWithTimeout from '@/helpers/api';
import { FlexpulseResponse } from '@/@types/api';
import { ProgressCardEntries } from '@/@types/store/progress';

const { t, locale } = i18n.global;

const actions: ActionTree<AppointmentState, RootState> = {
	cancelAppointment: async (
		{ dispatch },
		options: { appointment: CurrentAppointment; resetUI: boolean }
	): Promise<boolean> => {
		const { id = 0 } = options.appointment.data?.firstBlock || {};
		const { id: blockSecondId } = options.appointment.data?.secondBlock || {};

		const url = setQueryParams(ENDPOINTS.APPOINTMENT, 'json');

		const appointmentIds: number[] = [
			id,
			(typeof blockSecondId !== 'undefined' && blockSecondId) as number,
		].filter(Boolean);

		const requests = appointmentIds.map(
			async (appointmentId: number): Promise<AxiosResponse> => {
				const request: AxiosResponse = await API.delete(url, {
					apiKey: true,
					token: 'secure',
					data: {
						id: appointmentId,
					},
				}).catch((error) => {
					return Promise.reject(error);
				});

				return request;
			}
		);

		await Promise.all(requests)
			.then(async () => {
				await dispatch('fetchAppointmentData', {
					updateState: true,
					query: {
						availableHours: 0,
					},
					type: 'agenda',
				});

				await dispatch(
					'student/fetchStudentData',
					{
						updateState: true,
					},
					{
						root: true,
					}
				);

				if (!options.resetUI) return Promise.resolve(true);

				actionSheetController.dismiss();

				dispatch(
					'router/pushRoute',
					{
						direction: 'root',
						name: 'tabs.appointment',
					},
					{
						root: true,
					}
				);

				dispatch(
					'userInterface/showToast',
					{
						buttons: [
							{
								handler: () =>
									dispatch(
										'router/pushRoute',
										{
											name: 'tabs.appointment.planner',
										},
										{ root: true }
									),
								text: t('appointment.plan.cta_replan_short'),
							},
						],
						color: 'success',
						text: t('appointment.success.cancel'),
					},
					{
						root: true,
					}
				);
			})
			.catch(() => {
				dispatch(
					'userInterface/showToast',
					{
						color: 'danger',
						text: t('appointment.error.cancel'),
					},
					{
						root: true,
					}
				);

				return Promise.reject(false);
			});

		await dispatch('notification/cancelLocalNotification', id, {
			root: true,
		});

		return true;
	},
	fetchAppointmentById: async (
		{ dispatch },
		options: FetchAppointmentByIdOptions
	): Promise<CurrentAppointment | boolean> => {
		const { availableHours = 0, blockSecondId, id, updateState = true } = options;

		if (updateState) {
			await dispatch('setCurrentAppointment', {
				data: null,
				loaded: false,
			});
		}

		const firstBlock: FlexpulseResponse<Appointment[]> = await dispatch(
			'fetchAppointmentData',
			{
				query: {
					id,
					availableHours,
				},
				updateState: false,
			} as FetchAppointmentOptions
		);

		if (!firstBlock.result.length) {
			return false;
		}

		const secondBlock: FlexpulseResponse<Appointment[]> | null = blockSecondId
			? await dispatch('fetchAppointmentData', {
					query: {
						id: blockSecondId,
						availableHours,
					},
					updateState: false,
			  } as FetchAppointmentOptions)
			: null;

		const currentAppointment = {
			data: {
				firstBlock: firstBlock.result[0],
				secondBlock: secondBlock?.result[0] || null,
			},
			loaded: true,
		};

		if (updateState) {
			await dispatch('setCurrentAppointment', currentAppointment);
		}

		return currentAppointment;
	},
	fetchAppointmentData: async (
		{ dispatch, getters },
		options: FetchAppointmentOptions
	): Promise<AppointmentResponse | boolean> => {
		const url = setQueryParams(ENDPOINTS.APPOINTMENT, 'json', options.query);

		if (options.updateState && options.ignoreLoadingState !== true) {
			await dispatch('setAppointmentLoaded', {
				loaded: false,
				type: options.type,
			});
		}

		const request: AxiosResponse<AppointmentResponse> = await API.get(url, {
			apiKey: true,
			token: 'secure',
		}).catch(async (error: AxiosResponse) => {
			// User cannot plan
			if (error.status === 422) {
				await dispatch('student/setCanPlan', false, {
					root: true,
				});
			}

			if ([...REJECTED_AUTH_STATUS_CODES, 422].indexOf(error.status) === -1) {
				dispatch(
					'userInterface/showToast',
					{
						color: 'danger',
						text: t('appointment.error.fetch'),
					},
					{
						root: true,
					}
				);
			}

			return Promise.reject(error);
		});

		if (request?.data && options.updateState) {
			const { result } = request.data;
			const processedResult = result === null ? [] : result;

			await dispatch('setAppointmentList', {
				items: options.push
					? [...getters['list'](options.type), ...processedResult]
					: processedResult,
				type: options.type,
			});

			await dispatch('setAppointmentLoaded', {
				loaded: true,
				type: options.type,
			});

			if (options.fetchProgressCard) {
				await dispatch(
					'progress/fetchProgressCard',
					{
						query: {
							getProgCardOrLesson: 0,
							getLatestLesson: 1,
						},
						setHomeworkNotifications: true,
						updateState: true,
					},
					{
						root: true,
					}
				);
			}

			if (options.scheduleNotifications && result?.length > 0) {
				await dispatch('scheduleAppointmentNotifications', {
					appointments: getters.appointmentsByAppointmentState('planned', 'agenda'),
				});
			}

			return request.data;
		}

		return request.data || false;
	},
	putAppointment: async (
		{ commit, getters, rootGetters },
		options: PutAppointmentOptions
	): Promise<PutAppointmentResult | void> => {
		const url: string = setQueryParams(ENDPOINTS.APPOINTMENT, 'json');
		const student: Student = options.student || rootGetters['student/data'];

		if (getters.isAppointmentProcessing(options.appointment.id)) {
			return;
		}

		commit('SET_PROCESSING_APPOINTMENT', { id: options.appointment.id });

		const baseAppointmentOptions: AppointmentBaseOptions = {
			address: `${student.startStreet !== '' ? student.startStreet : student.street} ${
				student.startHouseNr !== '' ? student.startHouseNr : student.houseNr
			}`,
			city: student.startCity !== '' ? `${student.startCity}` : `${student.city}`,
			type: options.type || 'lesuur',
		};

		const { push: pushEvent } = useGTM({
			user_id: student.id,
		});

		/**
		 * Pepare error and data arrays to save errors
		 */
		const errorResponses: PutAppointmentResponse = [];
		const successResponses: PutAppointmentResponse = [];

		await API.put(
			url,
			{
				...baseAppointmentOptions,
				id: options.appointment.id,
			},
			{
				apiKey: true,
				token: 'secure',
			}
		)
			.then(async (response) => {
				successResponses.push({
					...response.data,
					id: options.appointment.id,
					blockSecondId: options.appointment.blockSecondId ?? false,
				});

				await API.put(
					url,
					{
						...baseAppointmentOptions,
						id: options.appointment.blockSecondId,
						mergeWithAppointmentId: options.appointment.id,
					},
					{
						apiKey: true,
						token: 'secure',
					}
				)
					.then((response) => {
						successResponses.push({
							...response.data,
							id: options.appointment.blockSecondId,
						});

						pushEvent('plan_rijles', {
							duration: options.appointment?.blockSecondId ? 100 : 50,
							id: options.appointment.id,
						});

						return Promise.resolve();
					})
					.catch((error) => {
						errorResponses.push({
							...error.data,
							id: options.appointment.id,
						});

						return Promise.resolve();
					});

				pushEvent('plan_rijles', {
					duration: options.appointment?.blockSecondId ? 100 : 50,
					id: options.appointment.id,
				});

				commit('SET_PROCESSING_APPOINTMENT', { id: options.appointment.id, add: false });

				return Promise.resolve();
			})
			.catch((error) => {
				errorResponses.push({
					...error.data,
					id: options.appointment.id,
				});

				return Promise.resolve();
			});

		return {
			errorResponses,
			successResponses,
		};
	},
	putAppointmentList: async (
		{ dispatch, rootGetters },
		options: PutAppointmentListOptions
	): Promise<PutAppointmentResult> => {
		const url: string = setQueryParams(ENDPOINTS.APPOINTMENT, 'json');
		const student: Student = options.studentFromStore
			? rootGetters['student/data']
			: options.student;

		const baseAppointmentOptions: AppointmentBaseOptions = {
			address: `${student.startStreet !== '' ? student.startStreet : student.street} ${
				student.startHouseNr !== '' ? student.startHouseNr : student.houseNr
			}`,
			city: student.startCity !== '' ? `${student.startCity}` : `${student.city}`,
			type: options.type || 'lesuur',
		};

		const { push: pushEvent } = useGTM({
			user_id: student.id,
		});

		/**
		 * Pepare error and data arrays to save errors
		 */
		const errorResponses: PutAppointmentResponse = [];
		const successResponses: PutAppointmentResponse = [];

		/**
		 * NOTE: We need to process second block to block full (double) lesson of 100 minutes
		 */
		const appointmentList = options.list.reduce(
			(prevValue: Array<number>, currentValue: Appointment) => [
				...prevValue,
				currentValue.id,
				currentValue.blockSecondId,
			],
			[]
		);

		await iterateSerialWithTimeout(appointmentList, 200, async (id: number) => {
			const appointmentData: Appointment = options.list.filter(
				(item: Appointment) => item.id === id
			)[0];

			const requestOptions: PutAppointmentPayload = {
				...baseAppointmentOptions,
				id,
			};

			await API.put(url, requestOptions, {
				apiKey: true,
				token: 'secure',
			})
				.then((response) => {
					successResponses.push({
						...response.data,
						id,
						blockSecondId: appointmentData?.blockSecondId ?? false,
					});

					pushEvent('plan_rijles', {
						duration: appointmentData?.blockSecondId ? 100 : 50,
						id,
					});

					return Promise.resolve();
				})
				.catch((error) => {
					errorResponses.push({
						...error.data,
						id,
						blockSecondId: appointmentData?.blockSecondId ?? false,
					});

					return Promise.resolve();
				});
		});

		if (options.fetchAppointmentList) {
			const fetchOptions: FetchAppointmentOptions = {
				updateState: true,
				query: {
					availableHours: 0,
				},
				scheduleNotifications: true,
				type: 'agenda',
			};

			await dispatch('fetchAppointmentData', fetchOptions);
		}

		if (options.fetchStudent) {
			const studentOptions: FetchStudentOptions = {
				updateState: true,
			};

			await dispatch('student/fetchStudentData', studentOptions, {
				root: true,
			});
		}

		if (options.resetQueue) {
			const setQueueOptions: SetAppointmentListPayload = {
				items: [],
				type: 'planQueue',
			};

			await dispatch('setAppointmentList', setQueueOptions);
		}

		return {
			errorResponses,
			successResponses,
		};
	},
	setCurrentAppointment: ({ commit }, payload: CurrentAppointment): void => {
		commit('SET_CURRENT_APPOINTMENT', payload);
	},
	scheduleAppointmentNotifications: async (
		{ dispatch, rootGetters },
		options: ScheduleAppointmentNotificationOptions
	): Promise<void> => {
		const { appointments, offset } = options;
		const notificationOffset = offset ?? DEFAULT_NOTIFICATION_OFFSET_HOURS;

		/**
		 * When score card has homework, update local notification.
		 * Save as array but only display first section in notification for now
		 */
		const cardsWithHomeWork: ProgressCardEntries =
			rootGetters['progress/cardEntriesWithHomeWork'];

		const notificationBody =
			cardsWithHomeWork.length > 0
				? t('appointment.notification.body_homework_single', {
						section: rootGetters['progress/cardCategoryTitleByNumber'](
							Number(cardsWithHomeWork[0].description?.charAt(0))
						),
				  })
				: t('appointment.notification.body');

		const notifications = appointments
			// Build notification array
			.map(
				(appointment: Appointment): CustomLocalNotificationInterface => ({
					body: notificationBody,
					id: appointment.id,
					targetDate: new Date(
						`${dayjs(`${appointment.date} ${appointment.timeStart}`).subtract(
							notificationOffset,
							'hour'
						)}`
					),
					title: t('appointment.notification.title', {
						type: t(`appointment.type.${appointment.type}`).toLowerCase(),
						offset: notificationOffset,
					}),
				})
			)
			// Remove notifications that are triggered after targetData and before appointment
			// to prevent infinite notifications between offset and actual start of appointment
			.filter((appointmentNotification: AppointmentNotification): boolean =>
				dayjs().isBefore(dayjs(appointmentNotification.targetDate))
			)
			// Map filtered response back to compatible array of notifications to be scheduled
			.map(
				(notification: CustomLocalNotificationInterface): LocalNotificationSchema => ({
					actionTypeId: cardsWithHomeWork.length > 0 ? 'router_setLocation' : undefined,
					body: notification.body,
					extra: {
						options:
							cardsWithHomeWork.length > 0
								? `/${locale}/t/${t('routes.tasks.slug')}`
								: undefined,
					},
					id: notification.id,
					schedule: {
						at: notification.targetDate,
					},
					title: notification.title,
				})
			);

		return dispatch('notification/scheduleLocalNotifications', notifications, {
			root: true,
		});
	},
	resetAppointmentData: ({ commit }): void => {
		return commit('RESET_APPOINTMENT_DATA');
	},
	setAppointmentFeedback: ({ commit }, payload: PutAppointmentResultPayload): void => {
		return commit('SET_APPOINTMENT_FEEDBACK', payload);
	},
	setAppointmentList: ({ commit }, payload: SetAppointmentListPayload): void => {
		return commit('SET_APPOINTMENT_LIST', payload);
	},
	setAppointmentLoaded: ({ commit }, payload: SetAppointmentLoadedPayload): void => {
		return commit('SET_APPOINTMENT_LOADED', payload);
	},
};

export { actions };
