// Vendor
import { createRouter, createWebHistory } from '@ionic/vue-router';

// Routes
import { routes } from '@/router/routes';
import { NavigationGuard } from './routes/guard.class';
import { NavigationHook } from './routes/hook.class';

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const navigationGuard = new NavigationGuard(to, from, next);

	await navigationGuard.processRouteVoters();

	navigationGuard.continue();
});

router.afterEach((to, from) => {
	const navigationHook = new NavigationHook(to, from);

	navigationHook.setDocumentTitle();
});

export { router };
