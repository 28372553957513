import { DateTimeFormat } from "@intlify/core-base";

const datetimeFormats: DateTimeFormat = {
    appointmentShort: {
        day: 'numeric',
        month: 'long',
        weekday: 'short',
    },
    appointmentLong: {
        day: 'numeric',
        month: 'long',
        weekday: 'long',
    },
    default: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    }
};

export {
    datetimeFormats
}
